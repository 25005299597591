// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/mobile-popup-xs.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/mobile-popup.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-popup[data-v-4480306a]{position:relative;display:flex;cursor:pointer;justify-content:space-between;border-radius:1.25rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-repeat:no-repeat;width:100%;height:21.5rem;background-position:top}@media(min-width:390px){.app-popup[data-v-4480306a]{height:23.75rem}}@media(min-width:393px){.app-popup[data-v-4480306a]{height:22.75rem}}@media(min-width:414px){.app-popup[data-v-4480306a]{height:24rem}}@media(min-width:640px){.app-popup[data-v-4480306a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");border-radius:6.875rem;height:30.5rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
