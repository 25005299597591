export const METRO_STATIONS = [
  {
    id: 82,
    title: 'Авиамоторная',
    line: {
      id: 8,
      title: 'Калининско-Солнцевская',
    },
  },
  {
    id: 29,
    title: 'Автозаводская',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 101,
    title: 'Академическая',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 54,
    title: 'Александровский сад',
    line: {
      id: 4,
      title: 'Филёвская',
    },
  },
  {
    id: 91,
    title: 'Алексеевская',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 540,
    title: 'Алма-Атинская',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 129,
    title: 'Алтуфьево',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 589,
    title: 'Андроновка',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 689,
    title: 'Аникеевка',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 163,
    title: 'Аннино',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 44,
    title: 'Арбатская',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 37,
    title: 'Аэропорт',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 87,
    title: 'Бабушкинская',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 61,
    title: 'Багратионовская',
    line: {
      id: 4,
      title: 'Филёвская',
    },
  },
  {
    id: 655,
    title: 'Баковка',
    line: {
      id: 47,
      title: 'МЦД1',
    },
  },
  {
    id: 604,
    title: 'Балтийская',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 120,
    title: 'Баррикадная',
    line: {
      id: 7,
      title: 'Таганско-Краснопресненская',
    },
  },
  {
    id: 47,
    title: 'Бауманская',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 122,
    title: 'Беговая',
    line: {
      id: 7,
      title: 'Таганско-Краснопресненская',
    },
  },
  {
    id: 585,
    title: 'Белокаменная',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 621,
    title: 'Беломорская',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 35,
    title: 'Белорусская',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 105,
    title: 'Беляево',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 641,
    title: 'Бескудниково',
    line: {
      id: 47,
      title: 'МЦД1',
    },
  },
  {
    id: 130,
    title: 'Бибирево',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 11,
    title: 'Библиотека им. Ленина',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 662,
    title: 'Битца',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 552,
    title: 'Битцевский парк',
    line: {
      id: 12,
      title: 'Бутовская',
    },
  },
  {
    id: 536,
    title: 'Борисово',
    line: {
      id: 10,
      title: 'Люблинско-Дмитровская',
    },
  },
  {
    id: 140,
    title: 'Боровицкая',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 616,
    title: 'Боровское шоссе',
    line: {
      id: 8,
      title: 'Калининско-Солнцевская',
    },
  },
  {
    id: 89,
    title: 'Ботанический сад',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 158,
    title: 'Братиславская',
    line: {
      id: 10,
      title: 'Люблинско-Дмитровская',
    },
  },
  {
    id: 193,
    title: 'Бульвар адмирала Ушакова',
    line: {
      id: 12,
      title: 'Бутовская',
    },
  },
  {
    id: 164,
    title: 'Бульвар Дмитрия Донского',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 1,
    title: 'Бульвар Рокоссовского',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 195,
    title: 'Бунинская аллея',
    line: {
      id: 12,
      title: 'Бутовская',
    },
  },
  {
    id: 661,
    title: 'Бутово',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 578,
    title: 'Бутырская',
    line: {
      id: 10,
      title: 'Люблинско-Дмитровская',
    },
  },
  {
    id: 27,
    title: 'Варшавская',
    line: {
      id: 11,
      title: 'Каховская',
    },
  },
  {
    id: 90,
    title: 'ВДНХ',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 594,
    title: 'Верхние Котлы',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 610,
    title: 'Верхние Лихоборы',
    line: {
      id: 10,
      title: 'Люблинско-Дмитровская',
    },
  },
  {
    id: 132,
    title: 'Владыкино',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 636,
    title: 'Водники',
    line: {
      id: 47,
      title: 'МЦД1',
    },
  },
  {
    id: 40,
    title: 'Водный стадион',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 39,
    title: 'Войковская',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 114,
    title: 'Волгоградский проспект',
    line: {
      id: 7,
      title: 'Таганско-Краснопресненская',
    },
  },
  {
    id: 156,
    title: 'Волжская',
    line: {
      id: 10,
      title: 'Люблинско-Дмитровская',
    },
  },
  {
    id: 524,
    title: 'Волоколамская',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 16,
    title: 'Воробьёвы горы',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 197,
    title: 'Выставочная',
    line: {
      id: 4,
      title: 'Филёвская',
    },
  },
  {
    id: 513,
    title: 'Выставочный центр',
    line: {
      id: 17,
      title: 'Монорельс (М1)',
    },
  },
  {
    id: 110,
    title: 'Выхино',
    line: {
      id: 7,
      title: 'Таганско-Краснопресненская',
    },
  },
  {
    id: 618,
    title: 'Говорово',
    line: {
      id: 8,
      title: 'Калининско-Солнцевская',
    },
  },
  {
    id: 678,
    title: 'Гражданская',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 642,
    title: 'Дегунино',
    line: {
      id: 47,
      title: 'МЦД1',
    },
  },
  {
    id: 555,
    title: 'Деловой центр',
    line: {
      id: 45,
      title: 'Большая кольцевая линия',
    },
  },
  {
    id: 598,
    title: 'Деловой центр (МЦК)',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 668,
    title: 'Депо',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 36,
    title: 'Динамо',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 135,
    title: 'Дмитровская',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 74,
    title: 'Добрынинская',
    line: {
      id: 5,
      title: 'Кольцевая',
    },
  },
  {
    id: 637,
    title: 'Долгопрудная',
    line: {
      id: 47,
      title: 'МЦД1',
    },
  },
  {
    id: 21,
    title: 'Домодедовская',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 526,
    title: 'Достоевская',
    line: {
      id: 10,
      title: 'Люблинско-Дмитровская',
    },
  },
  {
    id: 161,
    title: 'Дубровка',
    line: {
      id: 10,
      title: 'Люблинско-Дмитровская',
    },
  },
  {
    id: 551,
    title: 'Жулебино',
    line: {
      id: 7,
      title: 'Таганско-Краснопресненская',
    },
  },
  {
    id: 593,
    title: 'ЗИЛ',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 601,
    title: 'Зорге',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 534,
    title: 'Зябликово',
    line: {
      id: 10,
      title: 'Люблинско-Дмитровская',
    },
  },
  {
    id: 587,
    title: 'Измайлово',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 51,
    title: 'Измайловская',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 675,
    title: 'Каланчевская',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 672,
    title: 'Калитники',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 104,
    title: 'Калужская',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 24,
    title: 'Кантемировская',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 26,
    title: 'Каховская',
    line: {
      id: 11,
      title: 'Каховская',
    },
  },
  {
    id: 25,
    title: 'Каширская',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 42,
    title: 'Киевская',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 96,
    title: 'Китай-город',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 154,
    title: 'Кожуховская',
    line: {
      id: 10,
      title: 'Люблинско-Дмитровская',
    },
  },
  {
    id: 28,
    title: 'Коломенская',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 629,
    title: 'Коммунарка',
    line: {
      id: null,
      title: null,
    },
  },
  {
    id: 6,
    title: 'Комсомольская',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 106,
    title: 'Коньково',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 605,
    title: 'Коптево',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 622,
    title: 'Косино',
    line: {
      id: 46,
      title: 'Некрасовская',
    },
  },
  {
    id: 571,
    title: 'Котельники',
    line: {
      id: 7,
      title: 'Таганско-Краснопресненская',
    },
  },
  {
    id: 20,
    title: 'Красногвардейская',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 687,
    title: 'Красногорская',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 78,
    title: 'Краснопресненская',
    line: {
      id: 5,
      title: 'Кольцевая',
    },
  },
  {
    id: 5,
    title: 'Красносельская',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 7,
    title: 'Красные ворота',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 679,
    title: 'Красный Балтиец',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 663,
    title: 'Красный Строитель',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 153,
    title: 'Крестьянская застава',
    line: {
      id: 10,
      title: 'Люблинско-Дмитровская',
    },
  },
  {
    id: 12,
    title: 'Кропоткинская',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 66,
    title: 'Крылатское',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 595,
    title: 'Крымская',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 669,
    title: 'Кубанская',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 118,
    title: 'Кузнецкий мост',
    line: {
      id: 7,
      title: 'Таганско-Краснопресненская',
    },
  },
  {
    id: 112,
    title: 'Кузьминки',
    line: {
      id: 7,
      title: 'Таганско-Краснопресненская',
    },
  },
  {
    id: 64,
    title: 'Кунцевская',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 46,
    title: 'Курская',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 697,
    title: 'Курьяново',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 59,
    title: 'Кутузовская',
    line: {
      id: 4,
      title: 'Филёвская',
    },
  },
  {
    id: 100,
    title: 'Ленинский проспект',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 550,
    title: 'Лермонтовский проспект',
    line: {
      id: 7,
      title: 'Таганско-Краснопресненская',
    },
  },
  {
    id: 553,
    title: 'Лесопарковая',
    line: {
      id: 12,
      title: 'Бутовская',
    },
  },
  {
    id: 691,
    title: 'Лефортово',
    line: {
      id: 46,
      title: 'Некрасовская',
    },
  },
  {
    id: 640,
    title: 'Лианозово',
    line: {
      id: 47,
      title: 'МЦД1',
    },
  },
  {
    id: 606,
    title: 'Лихоборы',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 633,
    title: 'Лобня',
    line: {
      id: 47,
      title: 'МЦД1',
    },
  },
  {
    id: 586,
    title: 'Локомотив',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 576,
    title: 'Ломоносовский проспект',
    line: {
      id: 8,
      title: 'Калининско-Солнцевская',
    },
  },
  {
    id: 9,
    title: 'Лубянка',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 597,
    title: 'Лужники',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 624,
    title: 'Лухмановская',
    line: {
      id: 46,
      title: 'Некрасовская',
    },
  },
  {
    id: 157,
    title: 'Люблино',
    line: {
      id: 10,
      title: 'Люблинско-Дмитровская',
    },
  },
  {
    id: 639,
    title: 'Марк',
    line: {
      id: 47,
      title: 'МЦД1',
    },
  },
  {
    id: 84,
    title: 'Марксистская',
    line: {
      id: 8,
      title: 'Калининско-Солнцевская',
    },
  },
  {
    id: 527,
    title: 'Марьина роща',
    line: {
      id: 10,
      title: 'Люблинско-Дмитровская',
    },
  },
  {
    id: 159,
    title: 'Марьино',
    line: {
      id: 10,
      title: 'Люблинско-Дмитровская',
    },
  },
  {
    id: 34,
    title: 'Маяковская',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 86,
    title: 'Медведково',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 196,
    title: 'Международная',
    line: {
      id: 4,
      title: 'Филёвская',
    },
  },
  {
    id: 137,
    title: 'Менделеевская',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 575,
    title: 'Минская',
    line: {
      id: 8,
      title: 'Калининско-Солнцевская',
    },
  },
  {
    id: 525,
    title: 'Митино',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 620,
    title: 'Мичуринский проспект',
    line: {
      id: 8,
      title: 'Калининско-Солнцевская',
    },
  },
  {
    id: 65,
    title: 'Молодежная',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 673,
    title: 'Москва-Товарная',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 666,
    title: 'Москворечье',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 523,
    title: 'Мякинино',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 144,
    title: 'Нагатинская',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 145,
    title: 'Нагорная',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 690,
    title: 'Нахабино',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 146,
    title: 'Нахимовский проспект',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 625,
    title: 'Некрасовка',
    line: {
      id: 46,
      title: 'Некрасовская',
    },
  },
  {
    id: 653,
    title: 'Немчиновка',
    line: {
      id: 47,
      title: 'МЦД1',
    },
  },
  {
    id: 590,
    title: 'Нижегородская',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 79,
    title: 'Новогиреево',
    line: {
      id: 8,
      title: 'Калининско-Солнцевская',
    },
  },
  {
    id: 638,
    title: 'Новодачная',
    line: {
      id: 47,
      title: 'МЦД1',
    },
  },
  {
    id: 542,
    title: 'Новокосино',
    line: {
      id: 8,
      title: 'Калининско-Солнцевская',
    },
  },
  {
    id: 31,
    title: 'Новокузнецкая',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 615,
    title: 'Новопеределкино',
    line: {
      id: 8,
      title: 'Калининско-Солнцевская',
    },
  },
  {
    id: 68,
    title: 'Новослободская',
    line: {
      id: 5,
      title: 'Кольцевая',
    },
  },
  {
    id: 591,
    title: 'Новохохловская',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 109,
    title: 'Новоясеневская',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 103,
    title: 'Новые Черемушки',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 656,
    title: 'Одинцово',
    line: {
      id: 47,
      title: 'МЦД1',
    },
  },
  {
    id: 619,
    title: 'Озёрная',
    line: {
      id: 8,
      title: 'Калининско-Солнцевская',
    },
  },
  {
    id: 583,
    title: 'Окружная',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 693,
    title: 'Окская',
    line: {
      id: 46,
      title: 'Некрасовская',
    },
  },
  {
    id: 98,
    title: 'Октябрьская',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 124,
    title: 'Октябрьское поле',
    line: {
      id: 7,
      title: 'Таганско-Краснопресненская',
    },
  },
  {
    id: 628,
    title: 'Ольховая',
    line: {
      id: null,
      title: null,
    },
  },
  {
    id: 688,
    title: 'Опалиха',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 22,
    title: 'Орехово',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 659,
    title: 'Остафьево',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 131,
    title: 'Отрадное',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 10,
    title: 'Охотный ряд',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 30,
    title: 'Павелецкая',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 686,
    title: 'Павшино',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 602,
    title: 'Панфиловская',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 13,
    title: 'Парк Культуры',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 165,
    title: 'Парк Победы',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 50,
    title: 'Партизанская',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 685,
    title: 'Пенягино',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 52,
    title: 'Первомайская',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 667,
    title: 'Перерва',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 80,
    title: 'Перово',
    line: {
      id: 8,
      title: 'Калининско-Солнцевская',
    },
  },
  {
    id: 582,
    title: 'Петровский парк',
    line: {
      id: 45,
      title: 'Большая кольцевая линия',
    },
  },
  {
    id: 133,
    title: 'Петровско-Разумовская',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 155,
    title: 'Печатники',
    line: {
      id: 10,
      title: 'Люблинско-Дмитровская',
    },
  },
  {
    id: 63,
    title: 'Пионерская',
    line: {
      id: 4,
      title: 'Филёвская',
    },
  },
  {
    id: 128,
    title: 'Планерная',
    line: {
      id: 7,
      title: 'Таганско-Краснопресненская',
    },
  },
  {
    id: 596,
    title: 'Площадь Гагарина',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 83,
    title: 'Площадь Ильича',
    line: {
      id: 8,
      title: 'Калининско-Солнцевская',
    },
  },
  {
    id: 45,
    title: 'Площадь Революции',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 657,
    title: 'Подольск',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 664,
    title: 'Покровское',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 681,
    title: 'Покровское-Стрешнево',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 123,
    title: 'Полежаевская',
    line: {
      id: 7,
      title: 'Таганско-Краснопресненская',
    },
  },
  {
    id: 141,
    title: 'Полянка',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 150,
    title: 'Пражская',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 3,
    title: 'Преображенская площадь',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 627,
    title: 'Прокшино',
    line: {
      id: null,
      title: null,
    },
  },
  {
    id: 115,
    title: 'Пролетарская',
    line: {
      id: 7,
      title: 'Таганско-Краснопресненская',
    },
  },
  {
    id: 18,
    title: 'Проспект Вернадского',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 93,
    title: 'Проспект Мира',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 102,
    title: 'Профсоюзная',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 119,
    title: 'Пушкинская',
    line: {
      id: 7,
      title: 'Таганско-Краснопресненская',
    },
  },
  {
    id: 541,
    title: 'Пятницкое Шоссе',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 651,
    title: 'Рабочий поселок',
    line: {
      id: 47,
      title: 'МЦД1',
    },
  },
  {
    id: 577,
    title: 'Раменки',
    line: {
      id: 8,
      title: 'Калининско-Солнцевская',
    },
  },
  {
    id: 614,
    title: 'Рассказовка',
    line: {
      id: 8,
      title: 'Калининско-Солнцевская',
    },
  },
  {
    id: 41,
    title: 'Речной вокзал',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 92,
    title: 'Рижская',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 152,
    title: 'Римская',
    line: {
      id: 10,
      title: 'Люблинско-Дмитровская',
    },
  },
  {
    id: 584,
    title: 'Ростокино',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 572,
    title: 'Румянцево',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 111,
    title: 'Рязанский проспект',
    line: {
      id: 7,
      title: 'Таганско-Краснопресненская',
    },
  },
  {
    id: 136,
    title: 'Савеловская',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 574,
    title: 'Саларьево',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 88,
    title: 'Свиблово',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 147,
    title: 'Севастопольская',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 611,
    title: 'Селигерская',
    line: {
      id: 10,
      title: 'Люблинско-Дмитровская',
    },
  },
  {
    id: 49,
    title: 'Семеновская',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 142,
    title: 'Серпуховская',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 652,
    title: 'Сетунь',
    line: {
      id: 47,
      title: 'МЦД1',
    },
  },
  {
    id: 658,
    title: 'Силикатная',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 654,
    title: 'Сколково',
    line: {
      id: 47,
      title: 'МЦД1',
    },
  },
  {
    id: 201,
    title: 'Славянский бульвар',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 43,
    title: 'Смоленская',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 38,
    title: 'Сокол',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 588,
    title: 'Соколиная Гора',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 4,
    title: 'Сокольники',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 617,
    title: 'Солнцево',
    line: {
      id: 8,
      title: 'Калининско-Солнцевская',
    },
  },
  {
    id: 554,
    title: 'Спартак',
    line: {
      id: 7,
      title: 'Таганско-Краснопресненская',
    },
  },
  {
    id: 15,
    title: 'Спортивная',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 199,
    title: 'Сретенский бульвар',
    line: {
      id: 10,
      title: 'Люблинско-Дмитровская',
    },
  },
  {
    id: 692,
    title: 'Стахановская',
    line: {
      id: 46,
      title: 'Некрасовская',
    },
  },
  {
    id: 603,
    title: 'Стрешнево',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 198,
    title: 'Строгино',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 58,
    title: 'Студенческая',
    line: {
      id: 4,
      title: 'Филёвская',
    },
  },
  {
    id: 94,
    title: 'Сухаревская',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 127,
    title: 'Сходненская',
    line: {
      id: 7,
      title: 'Таганско-Краснопресненская',
    },
  },
  {
    id: 72,
    title: 'Таганская',
    line: {
      id: 5,
      title: 'Кольцевая',
    },
  },
  {
    id: 33,
    title: 'Тверская',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 32,
    title: 'Театральная',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 113,
    title: 'Текстильщики',
    line: {
      id: 7,
      title: 'Таганско-Краснопресненская',
    },
  },
  {
    id: 511,
    title: 'Телецентр',
    line: {
      id: 17,
      title: 'Монорельс (М1)',
    },
  },
  {
    id: 107,
    title: 'Теплый Стан',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 648,
    title: 'Тестовская',
    line: {
      id: 47,
      title: 'МЦД1',
    },
  },
  {
    id: 573,
    title: 'Технопарк',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 134,
    title: 'Тимирязевская',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 85,
    title: 'Третьяковская',
    line: {
      id: 8,
      title: 'Калининско-Солнцевская',
    },
  },
  {
    id: 683,
    title: 'Трикотажная',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 570,
    title: 'Тропарёво',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 200,
    title: 'Трубная',
    line: {
      id: 10,
      title: 'Люблинско-Дмитровская',
    },
  },
  {
    id: 143,
    title: 'Тульская',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 95,
    title: 'Тургеневская',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 126,
    title: 'Тушинская',
    line: {
      id: 7,
      title: 'Таганско-Краснопресненская',
    },
  },
  {
    id: 592,
    title: 'Угрешская',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 121,
    title: 'Улица 1905 года',
    line: {
      id: 7,
      title: 'Таганско-Краснопресненская',
    },
  },
  {
    id: 512,
    title: 'Улица Академика Королева',
    line: {
      id: 17,
      title: 'Монорельс (М1)',
    },
  },
  {
    id: 162,
    title: 'Улица Академика Янгеля',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 194,
    title: 'Улица Горчакова',
    line: {
      id: 12,
      title: 'Бутовская',
    },
  },
  {
    id: 623,
    title: 'Улица Дмитриевского',
    line: {
      id: 46,
      title: 'Некрасовская',
    },
  },
  {
    id: 510,
    title: 'Улица Милашенкова',
    line: {
      id: 17,
      title: 'Монорельс (М1)',
    },
  },
  {
    id: 514,
    title: 'Улица Сергея Эйзенштейна',
    line: {
      id: 17,
      title: 'Монорельс (М1)',
    },
  },
  {
    id: 192,
    title: 'Улица Скобелевская',
    line: {
      id: 12,
      title: 'Бутовская',
    },
  },
  {
    id: 191,
    title: 'Улица Старокачаловская',
    line: {
      id: 12,
      title: 'Бутовская',
    },
  },
  {
    id: 17,
    title: 'Университет',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 626,
    title: 'Филатов Луг',
    line: {
      id: null,
      title: null,
    },
  },
  {
    id: 62,
    title: 'Филевский парк',
    line: {
      id: 4,
      title: 'Филёвская',
    },
  },
  {
    id: 60,
    title: 'Фили',
    line: {
      id: 4,
      title: 'Филёвская',
    },
  },
  {
    id: 579,
    title: 'Фонвизинская',
    line: {
      id: 10,
      title: 'Люблинско-Дмитровская',
    },
  },
  {
    id: 14,
    title: 'Фрунзенская',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 635,
    title: 'Хлебниково',
    line: {
      id: 47,
      title: 'МЦД1',
    },
  },
  {
    id: 608,
    title: 'Ховрино',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 600,
    title: 'Хорошёво',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 580,
    title: 'Хорошёвская',
    line: {
      id: 45,
      title: 'Большая кольцевая линия',
    },
  },
  {
    id: 23,
    title: 'Царицыно',
    line: {
      id: 2,
      title: 'Замоскворецкая',
    },
  },
  {
    id: 138,
    title: 'Цветной бульвар',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 581,
    title: 'ЦСКА',
    line: {
      id: 45,
      title: 'Большая кольцевая линия',
    },
  },
  {
    id: 2,
    title: 'Черкизовская',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 148,
    title: 'Чертановская',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 139,
    title: 'Чеховская',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 8,
    title: 'Чистые пруды',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 151,
    title: 'Чкаловская',
    line: {
      id: 10,
      title: 'Люблинско-Дмитровская',
    },
  },
  {
    id: 99,
    title: 'Шаболовская',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
  {
    id: 599,
    title: 'Шелепиха',
    line: {
      id: 44,
      title: 'МЦК',
    },
  },
  {
    id: 634,
    title: 'Шереметьевская',
    line: {
      id: 47,
      title: 'МЦД1',
    },
  },
  {
    id: 535,
    title: 'Шипиловская',
    line: {
      id: 10,
      title: 'Люблинско-Дмитровская',
    },
  },
  {
    id: 81,
    title: 'Шоссе Энтузиастов',
    line: {
      id: 8,
      title: 'Калининско-Солнцевская',
    },
  },
  {
    id: 53,
    title: 'Щелковская',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 660,
    title: 'Щербинка',
    line: {
      id: 48,
      title: 'МЦД2',
    },
  },
  {
    id: 125,
    title: 'Щукинская',
    line: {
      id: 7,
      title: 'Таганско-Краснопресненская',
    },
  },
  {
    id: 48,
    title: 'Электрозаводская',
    line: {
      id: 3,
      title: 'Арбатско-Покровская',
    },
  },
  {
    id: 694,
    title: 'Юго-Восточная',
    line: {
      id: 46,
      title: 'Некрасовская',
    },
  },
  {
    id: 19,
    title: 'Юго-Западная',
    line: {
      id: 1,
      title: 'Сокольническая',
    },
  },
  {
    id: 149,
    title: 'Южная',
    line: {
      id: 9,
      title: 'Серпуховско-Тимирязевская',
    },
  },
  {
    id: 108,
    title: 'Ясенево',
    line: {
      id: 6,
      title: 'Калужско-Рижская',
    },
  },
]

export default METRO_STATIONS
