export const DEVICE_TYPES = {
  XLG: 'XLG',
  XL: 'XL',
  LG: 'LG',
  MD: 'MD',
  SM: 'SM',
  XS: 'XS',
}

export const DEVICE_TYPES_SIZES = {
  [DEVICE_TYPES.XLG]: 1536,
  [DEVICE_TYPES.XL]: 1280,
  [DEVICE_TYPES.LG]: 1024,
  [DEVICE_TYPES.MD]: 768,
  [DEVICE_TYPES.SM]: 640,
  [DEVICE_TYPES.XS]: 375,
}

export default {
  data() {
    return {
      device_type_current: '',
      page_size_current: '',
      DEVICE_TYPES,
      DEVICE_TYPES_SIZES,
    }
  },
  computed: {
    deviceType: {
      set(newType) {
        this.device_type_current = newType
      },
      get() {
        return this.device_type_current
      },
    },
  },
  methods: {
    determinatePageSize() {
      const WIN_WIDTH =
        window.outerWidth || document.body.getBoundingClientRect().width
      this.page_size_current = WIN_WIDTH
      switch (true) {
        case WIN_WIDTH > DEVICE_TYPES_SIZES[DEVICE_TYPES.XL]:
          this.deviceType = 'XLG'
          break
        case WIN_WIDTH <= DEVICE_TYPES_SIZES[DEVICE_TYPES.XL] &&
          WIN_WIDTH > DEVICE_TYPES_SIZES[DEVICE_TYPES.LG]:
          this.deviceType = 'XL'
          break
        case WIN_WIDTH <= DEVICE_TYPES_SIZES[DEVICE_TYPES.LG] &&
          WIN_WIDTH > DEVICE_TYPES_SIZES[DEVICE_TYPES.MD]:
          this.deviceType = 'LG'
          break
        case WIN_WIDTH <= DEVICE_TYPES_SIZES[DEVICE_TYPES.MD] &&
          WIN_WIDTH > DEVICE_TYPES_SIZES[DEVICE_TYPES.SM]:
          this.deviceType = 'MD'
          break
        case WIN_WIDTH <= DEVICE_TYPES_SIZES[DEVICE_TYPES.SM] &&
          WIN_WIDTH > DEVICE_TYPES_SIZES[DEVICE_TYPES.XS]:
          this.deviceType = 'SM'
          break
        default:
          this.deviceType = 'XS'
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.determinatePageSize)
    this.determinatePageSize()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.determinatePageSize)
  },
}
