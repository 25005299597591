var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.russianBanner)?_c('div',[(Object.keys(_vm.banner).length)?_c('div',{staticClass:"w-full py-10 md:py-0 banner__page"},[_c('div',{staticClass:"container mx-auto"},[(_vm.banner.name && _vm.banner.type !== 'image')?_c('div',{class:[
            'relative flex mx-5 flex-col md:flex-row justify-center items-center h-72 md:h-80 py-0 md:py-10 rounded-none md:rounded-small overflow-hidden banner__wrapper',
            _vm.alignClass ]},[_c('div',{class:[
              'absolute top-0 left-0 w-full h-full banner__bg',
              _vm.imgLeft && 'banner__bg-left' ]}),_vm._v(" "),_c('div',{class:[
              'flex flex-col z-10',
              _vm.imgLeft
                ? 'md:self-end md:content-end'
                : 'md:w-5/12 content-start' ]},[_c('h2',{class:[
                'w-52 md:w-80 text-center text-xl sm:text-3xl text-white pb-3 font-semibold',
                _vm.imgLeft ? 'md:text-right' : 'md:text-left' ]},[_vm._v("\n              "+_vm._s(_vm.banner.name)+"\n            ")]),_vm._v(" "),_c('p',{class:[
                'text-center text-white text-base',
                _vm.imgLeft ? 'md:text-right' : 'md:text-left' ]},[_vm._v("\n              "+_vm._s(_vm.banner.description)+"\n            ")]),_vm._v(" "),_c('base-button',{class:[
                'block w-36 sm:w-40 mt-10 mx-auto ',
                _vm.imgLeft ? 'md:ml-auto md:mr-0' : 'md:mx-0' ],attrs:{"type":'standard',"label":_vm.banner.content,"padding":'p-3'},on:{"click":function($event){return _vm.sendAnalytic()}}})],1),_vm._v(" "),_c('div',{class:[
              'hidden md:block absolute h-full lg:w-inherit banner__img-box',
              _vm.imgLeft ? 'left-0' : 'right-0' ]},[_c('div',{staticClass:"relative w-full h-full"},[_c('div',{class:[
                  'absolute  top-24 bg-blue-400 transform  z-0 rounded-middle lg:rounded-large w-full h-full',
                  _vm.imgCubeClass ]}),_vm._v(" "),_c('div',{class:[
                  'relative transform -translate-y-1/2 top-1/2 h-2/3 lg:h-full overflow-hidden',
                  _vm.imgClass ]},[_c('img',{staticClass:"relative transform -translate-y-1/2 top-1/2 h-2/3 lg:h-full object-cover z-10 pointer-events-none",attrs:{"srcset":_vm.banner.srcSet,"sizes":_vm.banner.sizes,"src":_vm.banner.desktopUrl,"alt":""}})])])])]):_vm._e(),_vm._v(" "),(_vm.banner.type && _vm.banner.type === 'image')?_c('div',{staticClass:"flex justify-center"},[_c('img',{staticClass:"cursor-pointer",attrs:{"srcset":_vm.banner.srcSet,"sizes":_vm.banner.sizes,"src":_vm.banner.desktopUrl,"alt":"banner-image"},on:{"click":function($event){return _vm.openPageImageBanner(_vm.banner.href)}}})]):_vm._e(),_vm._v(" "),(_vm.preview && !_vm.banner.name)?_c('div',{staticClass:"relative mx-5 flex flex-col md:flex-row justify-center items-center h-72 md:h-80 py-0 md:py-10 px-5 sm:pr-0 sm:pl-10 lg:pl-20 rounded-none md:rounded-small overflow-hidden text-xl bg-gray-200"},[_vm._v("\n          Баннер \"Под картой\"\n        ")]):_vm._e()])]):_vm._e()]):_c('div',{staticClass:"w-full pb-6 md:pb-0 banner__page russian-banner relative"},[_c('img',{staticClass:"container mx-auto px-5 russian-banner-img",attrs:{"src":require("assets/images/russian-banner.jpg"),"alt":"russian-banner"}}),_vm._v(" "),_c('img',{staticClass:"container mx-auto px-5 russian-banner-mobile",attrs:{"src":require("assets/images/russian-banner-mobile.jpg"),"alt":"russian-banner-mobile"}})])])}
var staticRenderFns = []

export { render, staticRenderFns }