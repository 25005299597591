//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'lodash'
import { mapState } from 'vuex'
import AnalyticCore from '~/services/analytics'
import BaseInput from '~/components/UI/form/BaseInput.vue'
import BaseButton from '~/components/UI/BaseButton.vue'

export default {
  name: 'CommonJumbotron',
  components: {
    BaseInput,
    BaseButton,
  },
  data() {
    return {
      isMobile: '',
      search: undefined,
      salaryFrom: undefined,
    }
  },
  computed: {
    ...mapState('core', {
      localCity: 'city',
    }),
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  methods: {
    onVacanciesRedirect() {
      const searchInput = this.search
      const search = searchInput?.replace(/^[^a-zа-яё]*([a-zа-яё])/i, (m) => {
        return m?.toUpperCase() || undefined
      })
      const salaryFrom = this.salaryFrom
      this.$router.push({
        path: `vacancies`,
        query: { search, salaryFrom },
      })
    },
    onResize() {
      this.isMobile = document.documentElement.clientWidth < 1024
    },
    onMapRedirect() {
      const search = this.search
      const position = search?.replace(/^[^a-zа-яё]*([a-zа-яё])/i, (m) => {
        return m?.toUpperCase()
      })
      const salaryFrom = this.salaryFrom
      this.$router
        .push({
          path: `/`,
          query: { position, salaryFrom },
          hash: 'map',
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            throw error
          }
        })
      const el = document.getElementById('map')
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
      this.search = undefined
      this.salaryFrom = undefined
      this.sendAnalytic()
    },
    sendAnalytic() {
      if (this.$Analytics) {
        this.$Analytics.push({
          ...AnalyticCore.EVENT_BASE,
          event: 'search_map',
          city: this.localCity?.city || '',
          utm_campaign: get(this.$route.query, 'utm_campaign', ''),
          utm_content: get(this.$route.query, 'utm_content', ''),
          utm_medium: get(this.$route.query, 'utm_medium', ''),
          utm_source: get(this.$route.query, 'utm_source', ''),
          utm_term: get(this.$route.query, 'utm_term', ''),
          path: window.location.href,
          //
          eventAction: AnalyticCore.EVENT_ACTION.click,
          eventCategory: AnalyticCore.EVENT_CATEGORY.interaction,
          eventLabel: 'button',
          eventContext: 'vacancyMap',
          eventContent: null,
          eventLocation: 'main',
        })
      }
    },
  },
}
