//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { get } from 'lodash'
import AnalyticCore from '~/services/analytics'
import BaseCard from '~/components/UI/Cards/BaseCard.vue'

export default {
  name: 'VacancyFilterCards',
  components: {
    BaseCard,
  },
  data() {
    return {
      filters: [
        {
          title: 'Полная занятость',
          icon: 'wall-clock',
          link: '/vacancies?employment=Полная',
        },
        {
          title: 'Подработка',
          icon: '30-minutes',
          link: '/vacancies?employment=Подработка',
        },
        {
          title: 'Вахта',
          icon: '24-hours',
          link: '/vacancies?employment=Вахта',
        },
      ],
    }
  },
  computed: {
    ...mapState('core', {
      localCity: 'city',
    }),
  },
  methods: {
    sendAnalytic(title) {
      if (this.$Analytics) {
        this.$Analytics.push({
          ...AnalyticCore.EVENT_BASE,
          event: `home_card_${title.split('=')[1] || 0}`,
          city: this.localCity?.city || '',
          utm_campaign: get(this.$route.query, 'utm_campaign', ''),
          utm_content: get(this.$route.query, 'utm_content', ''),
          utm_medium: get(this.$route.query, 'utm_medium', ''),
          utm_source: get(this.$route.query, 'utm_source', ''),
          utm_term: get(this.$route.query, 'utm_term', ''),
          path: window.location.href,
          //
          eventAction: AnalyticCore.EVENT_ACTION.click,
          eventCategory: AnalyticCore.EVENT_CATEGORY.interaction,
          eventLabel: 'link',
          eventContext: 'vacancy',
          eventContent: null,
          eventLocation: 'main',
        })
      }
    },
  },
}
