//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import { cloneDeep, get, pickBy } from 'lodash'
// import AnalyticCore from '~/services/analytics'
import BaseButton from '~/components/UI/BaseButton.vue'
import BaseSelect from '~/components/UI/form/BaseSelect.vue'
import METRO_STATIONS from '~/common/constants/metroStations'
import TagsRadioButtons from '~/components/UI/TagsRadioButtons.vue'
import SalarySlider from '~/components/UI/SalarySlider.vue'
import FilterCity from '~/components/widgets/FilterCity.vue'

const FILTER_LABELS = {
  salaryFrom: 'Зарплата от',
  position: 'Должность',
  employment: 'Тип занятости',
  sort: 'Сортировка',
  search: 'Поиск',
  tag: 'Тег',
}

export default {
  name: 'VacancyFilters',
  components: {
    FilterCity,
    SalarySlider,
    TagsRadioButtons,
    BaseButton,
    BaseSelect,
  },
  props: {
    isSort: {
      type: Boolean,
      default: true,
    },
    isMap: {
      type: Boolean,
      default: false,
    },
    isDynamicPage: {
      type: Boolean,
      default: false,
    },
    hideFilters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      FILTER_LABELS,
      filtersForm: {
        search: '',
        salaryFrom: null,
        salaryTo: null,
        position: '',
        employment: '',
        sort: '',
        metro: '',
        tag: '',
      },
      appliedFilters: {
        search: '',
        salaryFrom: null,
        position: '',
        employment: '',
        sort: '',
        metro: '',
        tag: '',
        salaryTo: null,
      },
      showPicker: false,
      renderCounter: 0,
      isApplied: false,
      paginationOffset: 0,
      city: '',
    }
  },
  computed: {
    ...mapState('core', {
      localCity: 'city',
    }),
    ...mapState('dictionary', [
      'positions',
      'typeEmployment',
      'isLoaded',
      'tags',
    ]),
    ...mapState('meta', ['pageContent']),
    optionsSort() {
      return [
        { id: 'DATE_OPEN_DESC', name: 'По дате размещения' },
        { id: 'SALARY_DESC', name: 'По уменьшению зарплаты' },
        { id: 'SALARY_ASC', name: 'По увеличению зарплаты' },
      ]
    },
    pageConfigCity() {
      return this.pageContent?.filters?.cityFiasId || ''
    },
    optionsMetroStations() {
      return METRO_STATIONS || []
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (
          !this.isApplied &&
          this.isLoaded &&
          (this.localCity?.city || this.pageConfigCity) &&
          this.positions.length
        ) {
          this.applyFilters()
        }
      },
    },
    localCity: {
      immediate: true,
      handler(val) {
        if (val && val !== this.filtersForm.city && !this.isApplied) {
          this.applyFilters()
        }
      },
    },
  },
  mounted() {
    this.city = this?.localCity?.city || ''
  },
  methods: {
    ...mapActions('user', ['getUserCity']),
    parseUrl() {
      const url = this.$route.query
      /**
       * ?sort=1&page=2&limit=3&sellery=3&position=4&employed_type=5
       * sort = 'field' ASC || -'field' DESC
       * page = 1
       * limit = 10
       * ...filters
       * salaryFrom=1000
       * position=asdasd
       * employment=asdasdasd
       */

      this.appliedFilters = {}

      if (url.position) {
        this.appliedFilters.position = this.positions.find(
          (item) => item.key === url.position
        )
      }
      if (url.employment) {
        this.appliedFilters.employment = this.typeEmployment.find(
          (item) => item.key === url.employment
        )
      }
      if (url.salaryFrom) {
        this.appliedFilters.salaryFrom = +url.salaryFrom
      }
      if (url.salaryTo) {
        this.appliedFilters.salaryTo = +url.salaryTo
      }
      if (url.search) {
        this.appliedFilters.search = url.search
      }
      if (url.metro) {
        this.appliedFilters.metro = this.optionsMetroStations.find(
          (item) => item.title === url.metro
        )
      }
      if (url.tag) {
        this.appliedFilters.tag = url.tag
      }
      this.paginationOffset = +url.offset || 0
      this.filtersForm = cloneDeep(this.appliedFilters)
      this.filtersForm.offset = this.paginationOffset
    },
    routeTo() {
      const floatFilters = {
        salaryFrom: get(this.filtersForm, 'salaryFrom'),
        salaryTo: get(this.filtersForm, 'salaryTo'),
        position: get(this.filtersForm, 'position.key'),
        employment: get(this.filtersForm, 'employment.key'),
        sort: get(this.filtersForm, 'sort.id'),
        search: get(this.filtersForm, 'search'),
        metro: get(this.filtersForm, 'metro.title'),
        tag: get(this.filtersForm, 'tag'),
      }
      if (floatFilters.sort === this.optionsSort[0].id) {
        delete floatFilters.sort
      }
      const targetFilters = pickBy(floatFilters)
      let targetUrl = this.$route.path
      if (Object.keys(targetFilters).length) {
        const targetSearchParams = new URLSearchParams(pickBy(targetFilters))
        targetUrl += `?${targetSearchParams}`
      }
      const routePayload = {
        path: targetUrl,
      }
      if (this.isMap) {
        routePayload.hash = 'map'
      }
      if (this.isDynamicPage) {
        routePayload.hash = 'vacancies'
      }
      this.$router.push(routePayload).catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
      // this.sendAnalyticSubmit(targetFilters)
    },
    resetFilter() {
      this.filtersForm = {}
      const routePayload = {
        path: '/vacancies',
      }
      if (this.isDynamicPage) {
        routePayload.hash = 'vacancies'
        this.$router.replace({ hash: 'vacancies' }).catch(() => {})
      } else if (this.isMap) {
        routePayload.hash = 'map'
        routePayload.path = '/'
        this.$router.replace(routePayload).catch(() => {})
      } else {
        this.$router.replace({}).catch(() => {})
      }
    },
    onChangeCity() {
      this.showPicker = true
      // this.sendAnalytic('city')
      if (!this.isMap) {
        this.routeTo()
      }
    },
    closePicker() {
      this.showPicker = false
    },
    onFilterChange(event) {
      this.renderCounter += 1
      this.paginationOffset = 0
      if (event) {
        // this.sendAnalytic(event)
      }
      if (!this.isMap) {
        this.routeTo()
      }
    },
    // sendAnalytic(name) {
    //   if (this.$Analytics) {
    //     if (name === 'sort') {
    //       this.$Analytics.push({
    //         ...AnalyticCore.EVENT_BASE,
    //         event: 'click_vacancies_sort',
    //         city: this.localCity || '',
    //         utm_campaign: get(this.$route.query, 'utm_campaign', ''),
    //         utm_content: get(this.$route.query, 'utm_content', ''),
    //         utm_medium: get(this.$route.query, 'utm_medium', ''),
    //         utm_source: get(this.$route.query, 'utm_source', ''),
    //         utm_term: get(this.$route.query, 'utm_term', ''),
    //         path: window.location.href,
    //         //
    //         eventAction: AnalyticCore.EVENT_ACTION.click,
    //         eventCategory: AnalyticCore.EVENT_CATEGORY.interaction,
    //         eventLabel: 'sort',
    //         eventContext: null,
    //         eventContent: name || '',
    //         eventLocation: 'vacancies',
    //       })
    //     } else {
    //       this.$Analytics.push({
    //         ...AnalyticCore.EVENT_BASE,
    //         event: 'click_vacancies_filter',
    //         city: this.localCity || '',
    //         utm_campaign: get(this.$route.query, 'utm_campaign', ''),
    //         utm_content: get(this.$route.query, 'utm_content', ''),
    //         utm_medium: get(this.$route.query, 'utm_medium', ''),
    //         utm_source: get(this.$route.query, 'utm_source', ''),
    //         utm_term: get(this.$route.query, 'utm_term', ''),
    //         path: window.location.href,
    //         //
    //         eventAction: AnalyticCore.EVENT_ACTION.click,
    //         eventCategory: AnalyticCore.EVENT_CATEGORY.interaction,
    //         eventLabel: 'filterOn',
    //         eventContext: name || '',
    //         eventContent: name || '',
    //         eventLocation: 'vacancies',
    //       })
    //     }
    //   }
    // },
    // sendAnalyticSubmit(targetFilters) {
    //   if (this.$Analytics) {
    //     this.$Analytics.push({
    //       ...AnalyticCore.EVENT_BASE,
    //       event: 'click_filter',
    //       city: this.localCity || '',
    //       utm_campaign: get(this.$route.query, 'utm_campaign', ''),
    //       utm_content: get(this.$route.query, 'utm_content', ''),
    //       utm_medium: get(this.$route.query, 'utm_medium', ''),
    //       utm_source: get(this.$route.query, 'utm_source', ''),
    //       utm_term: get(this.$route.query, 'utm_term', ''),
    //       path: window.location.href,
    //       //
    //       eventAction: AnalyticCore.EVENT_ACTION.click,
    //       eventCategory: AnalyticCore.EVENT_CATEGORY.interaction,
    //       eventLabel: 'filter',
    //       eventContext: targetFilters || null,
    //       eventContent: null,
    //       eventLocation: 'vacancies',
    //     })
    //   }
    // },
    async applyFilters() {
      this.isApplied = true
      await this.parseUrl()
      const payload = this.filtersForm
      payload.offset = this.paginationOffset
      this.$emit('apply', payload)
      this.isApplied = false
    },
    changeSalary(e) {
      this.filtersForm.salaryFrom = e?.from || 0
      this.filtersForm.salaryTo = e?.to || 0
      if (!this.isMap) {
        this.routeTo()
      }
    },
    onChangeTag() {
      if (!this.isMap) {
        this.routeTo()
      }
    },
  },
}
