//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'CommonSlider',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    id: {
      type: [String, Number],
      default: '1',
    },
    options: {
      type: Object,
      default: null,
    },
  },
  methods: {
    handleClickSlide() {
      this.$emit('click-slide')
    },
  },
}
