//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { splitText } from '~/common/helpers/splitText'

export default {
  name: 'PersonaAvatar',
  props: {
    name: {
      type: String,
      default: '',
    },
    details: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      imageSrc: '',
    }
  },
  computed: {
    initials() {
      if (this.name) {
        if (!this.name.includes(' ')) return this.name.slice(0, 1)
        const [firstName, lastName] = this.name.split(' ')
        return firstName.slice(0, 1) + lastName.slice(0, 1)
      }
      return ''
    },
    nameSplit() {
      return !this.full ? splitText(this.name, 15) : this.name
    },
  },
  mounted() {
    this.imageSrc = this.image
  },
  methods: {
    replaceByDefault() {
      this.imageSrc = ''
    },
  },
}
