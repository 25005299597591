//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from 'vue-multiselect'
import VModelMixin from '~/common/mixins/VModelMixin'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: 'BaseSelect',
  components: {
    Multiselect,
  },
  mixins: [VModelMixin],
  props: {
    name: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    selectLabel: {
      type: String,
      default: '',
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    star: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    internalSearch: {
      type: Boolean,
      default: true,
    },
    clearOnSelect: {
      type: Boolean,
      default: true,
    },
    trackBy: {
      type: String,
      default: '',
    },
    groupLabel: {
      type: String,
      default: '',
    },
    groupValues: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    groupSelect: {
      type: Boolean,
      default: false,
    },
    iconLeft: {
      type: String,
      default: '',
    },
    openDirection: {
      type: String,
      default: '',
    },
    hideSelected: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: 'info', // error, success
      validator: (value) => {
        return ['error', 'success', 'info'].includes(value) >= 0
      },
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rootClasses() {
      const baseClass = 'custom-select'
      const res = [baseClass]
      if (this.required) {
        res.push('required')
      }
      if (this.disabled) {
        res.push('disabled')
      }
      if (this.status) {
        res.push(`is-status-${this.status}`)
      }
      if (this.iconLeft) {
        res.push('has-icon-left')
      }
      return res
    },
    multiselectClasses() {
      const baseClass = 'ui-select'
      const res = [baseClass]
      if (this.iconLeft) {
        res.push('has-icon-left')
      }
      return res
    },
  },
  methods: {
    onSelect(event) {
      this.$emit('onSelect', event)
    },
    onTag(event) {
      this.$emit('tag', event)
    },
    onChange(event) {
      if (!this.multiple) {
        this.change(event)
      } else {
        this.model.push(event)
        this.$emit('input', this.model)
      }
      this.$emit('change', event)
    },
    onClose(event) {
      this.$emit('close', event)
    },
    onRemove(event) {
      if (this.multiple) {
        const index = this.model.indexOf(event)
        this.model.splice(index, 1)
      }
      this.$emit('onRemove', event)
    },
    onSearchChange(event) {
      this.$emit('searchChange', event)
    },
    onClear() {
      this.$emit('input', null)
    },
    onOpen() {
      this.$emit('onOpen')
    },
  },
}
