//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'lodash'
import { mapState } from 'vuex'
import AnalyticCore from '~/services/analytics'
import PersonaAvatar from '~/components/UI/PersonaAvatar.vue'
import Slider from '~/components/UI/Slider.vue'

export default {
  name: 'CommonReviews',
  components: {
    PersonaAvatar,
    Slider,
  },
  data() {
    return {
      reviews: [
        {
          image: '',
          details: 'Нашел работу на Rabotut',
          name: 'Стас',
          text: 'Работал через этот сервис разнорабочим в гипермаркете. Нигде не обманывали, все согласно документам. Платят конечно не особо много, но если вам нужно сейчас и здесь найти какую-то подработку, чтобы накопить денег, по моему Работут это самый хороший вариант. Тут вы будете работать официально и будете уверены, что вас не кинут.',
        },
        {
          image: '',
          details: 'Нашел работу на Rabotut',
          name: 'Максим',
          text: 'Спасибо за ваше внимательное отношение! Нашёл работу очень быстро, оформление заняло всего пару часов. Помогли разобраться с новыми обязанностями, объяснили что и как делать, работаю уже 3й месяц.',
        },
        {
          image: '',
          details: 'Нашла работу на Rabotut',
          name: 'Наталья Ильина',
          text: 'Обычно я не обращаюсь к посреднику при трудоустройстве, но в этот раз двоюродный брат посоветовал позвонить в Работут. Мне все четенько по телефону разложили, прошлись по вакансиям и сказали какие нужны документы.  Зарплата норм, авансы вовремя, трудоустройство официальное. А брату за меня еще и денежный бонус отсыпали)',
        },
        {
          image: '',
          details: 'Нашла работу на Rabotut',
          name: 'Оксана',
          text: 'Устроилась продавцом. Все нормально, документы оформили за один день, на работу вышла сразу. Через пару дней аванс, посмотрим.',
        },
        {
          image: '',
          details: 'Нашла работу на Rabotut',
          name: 'Мария В.',
          text: 'Недавно устроилась через эту компанию. Всё как в объявлении, без сюрпризов. Работаю 2 месяца. Коллеги дружные, начальство адекватное.',
        },
        {
          image: '',
          details: 'Нашел работу на Rabotut',
          name: 'Алексей Баев',
          text: 'Искал подработку, оставил отклик. Позвонили сразу и также быстро оформили. Работа не сложная, платят честно. Деньги на карту сразу приходят.',
        },
        {
          image: '',
          details: 'Нашел работу на Rabotut',
          name: 'Михаил',
          text: 'Нашел вакансию водителя. Менеджер вежливый, подобрал много вариантов. Город у нас небольшой, но работу нашли. Спасибо!',
        },
      ],
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 20,
        autoUpdate: false,
        loop: true,
        direction: 'horizontal',
        pagination: {
          el: '.swiper-pagination-reviews',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next-reviews',
          prevEl: '.swiper-button-prev-reviews',
        },
        breakpoints: {
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        },
      },
    }
  },
  computed: {
    ...mapState('core', {
      localCity: 'city',
    }),
  },
  methods: {
    sendAnalytic() {
      if (this.$Analytics) {
        this.$Analytics.push({
          ...AnalyticCore.EVENT_BASE,
          event: 'click_reviews',
          city: this.localCity?.city || '',
          utm_campaign: get(this.$route.query, 'utm_campaign', ''),
          utm_content: get(this.$route.query, 'utm_content', ''),
          utm_medium: get(this.$route.query, 'utm_medium', ''),
          utm_source: get(this.$route.query, 'utm_source', ''),
          utm_term: get(this.$route.query, 'utm_term', ''),
          path: window.location.href,
          eventAction: AnalyticCore.EVENT_ACTION.click,
          eventCategory: AnalyticCore.EVENT_CATEGORY.interaction,
          eventLabel: 'button',
          eventContext: null,
          eventContent: 'feedback',
          eventLocation: 'main',
        })
      }
    },
  },
}
