//
//
//
//
//
//
//
//

export default {
  name: 'CommonSalary',
  props: {
    salary: {
      type: Number,
      default: 0,
    },
    classes: {
      type: String,
      default: '',
    },
  },
  computed: {
    salarySplited() {
      return `${this.salary}`.split(/(?=(?:\d{3})+(?!\d))/).join(' ')
    },
  },
}
