var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"w-full p-5 bg-white rounded-medium vacancy-filters"},[_c('div',{staticClass:"flex justify-between pb-5"},[_c('span',{staticClass:"inline leading-6 text-lg font-semibold"},[_vm._v(" Фильтры ")]),_vm._v(" "),_c('base-button',{staticClass:"font-semibold btn-transparent",attrs:{"type":"standard"},on:{"click":_vm.resetFilter}},[_vm._v("\n      Сбросить фильтр\n    ")])],1),_vm._v(" "),_c('div',{key:_vm.renderCounter,staticClass:"flex flex-col"},[(
        (!_vm.hideFilters.includes('city') && _vm.isDynamicPage) || !_vm.isDynamicPage
      )?_c('filter-city',{staticClass:"mb-5 w-full"}):_vm._e(),_vm._v(" "),(
        (!_vm.hideFilters.includes('position') && _vm.isDynamicPage) || !_vm.isDynamicPage
      )?_c('base-select',{staticClass:"mb-5",attrs:{"options":_vm.positions || [],"select-label":"Должность","label":"name","track-by":"key","placeholder":"Укажите желаемую должность","clearable":""},on:{"input":function($event){return _vm.onFilterChange('positions')}},model:{value:(_vm.filtersForm.position),callback:function ($$v) {_vm.$set(_vm.filtersForm, "position", $$v)},expression:"filtersForm.position"}}):_vm._e(),_vm._v(" "),(
        (!_vm.hideFilters.includes('employment') && _vm.isDynamicPage) ||
        !_vm.isDynamicPage
      )?_c('base-select',{staticClass:"mb-5",attrs:{"options":_vm.typeEmployment,"label":"name","placeholder":"Тип занятости","select-label":"Занятость","clearable":""},on:{"input":function($event){return _vm.onFilterChange('typeEmployment')}},model:{value:(_vm.filtersForm.employment),callback:function ($$v) {_vm.$set(_vm.filtersForm, "employment", $$v)},expression:"filtersForm.employment"}}):_vm._e(),_vm._v(" "),(_vm.city === 'Москва')?_c('base-select',{staticClass:"mb-5",attrs:{"options":_vm.optionsMetroStations,"label":"title","placeholder":"Название станции","select-label":"Станция метро","clearable":""},on:{"input":function($event){return _vm.onFilterChange('metroStations')}},model:{value:(_vm.filtersForm.metro),callback:function ($$v) {_vm.$set(_vm.filtersForm, "metro", $$v)},expression:"filtersForm.metro"}}):_vm._e(),_vm._v(" "),(
        (!_vm.hideFilters.includes('salary') && _vm.isDynamicPage) || !_vm.isDynamicPage
      )?_c('salary-slider',{attrs:{"min":0,"max":100000,"from":_vm.filtersForm.salaryFrom,"to":_vm.filtersForm.salaryTo,"label":"Уровень дохода"},on:{"onChange":_vm.changeSalary}}):_vm._e(),_vm._v(" "),(
        _vm.tags.length &&
        ((!_vm.hideFilters.includes('tag') && _vm.isDynamicPage) || !_vm.isDynamicPage)
      )?_c('tags-radio-buttons',{on:{"onChange":_vm.onChangeTag},model:{value:(_vm.filtersForm.tag),callback:function ($$v) {_vm.$set(_vm.filtersForm, "tag", $$v)},expression:"filtersForm.tag"}}):_vm._e(),_vm._v(" "),(_vm.isMap)?_c('base-button',{staticClass:"w-full py-3 mt-5",attrs:{"type":"outlined"},on:{"click":_vm.routeTo}},[_vm._v("\n      Применить\n    ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }