//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'lodash'
import { mapActions, mapState } from 'vuex'
import AnalyticCore from '~/services/analytics'
import BaseCardVacancy from '~/components/UI/Cards/BaseCardVacancy.vue'
import ModalResponse from '~/components/ModalResponse.vue'
import ReplySuccessModal from '~/components/ReplySuccessModal.vue'
import Modal from '~/components/UI/BaseModal.vue'

export default {
  name: 'HotVacancies',
  components: {
    ModalResponse,
    BaseCardVacancy,
    Modal,
    ReplySuccessModal,
  },
  data() {
    return {
      isModal: false,
      current: null,
      showConfirm: false,
      showResponseModal: false,
    }
  },
  computed: {
    ...mapState('core', {
      city: 'city',
    }),
    ...mapState('dictionary', ['positions', 'isLoaded']),
    ...mapState('vacancies', {
      fireVacancies: 'hotList',
    }),
  },
  watch: {
    city: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.getFireVacancies()
        }
      },
    },
  },
  methods: {
    ...mapActions('user', ['searchCities']),
    ...mapActions('vacancies', ['loadHotVacancies']),
    getFireVacancies() {
      const utmContent = this.$route.query?.utm_content
      const utmConfig = {
        merch: this.positions.find(
          (item) => item?.name?.toLowerCase() === 'мерчендайзер'
        ),
        kassir: this.positions.find(
          (item) => item?.name?.toLowerCase() === 'кассир'
        ),
        komplektovschik: this.positions.find(
          (item) => item?.name?.toLowerCase() === 'комплектовщик'
        ),
        sbor: this.positions.find(
          (item) => item?.name?.toLowerCase() === 'сборщик'
        ),
        rtz: this.positions.find(
          (item) => item?.name?.toLowerCase() === 'работник торгового зала'
        ),
        voditel: this.positions.find(
          (item) => item?.name?.toLowerCase() === 'водитель'
        ),
        gruzchik: this.positions.find(
          (item) => item?.name?.toLowerCase() === 'грузчик'
        ),
        podrabotka: this.positions.find(
          (item) => item?.name?.toLowerCase() === 'подработка'
        ),
      }
      if (utmConfig[utmContent]) {
        this.loadHotVacancies({
          type: 'hotList',
          position: utmConfig[utmContent],
          limit: 6,
          offset: 0,
        })
      } else {
        this.loadHotVacancies({ type: 'hotList', limit: 6, offset: 0 })
      }
    },
    showModal(event) {
      this.isModal = true
      this.openResponseModal()
      this.current = event
      this.sendAnalytic()
    },
    sendAnalytic() {
      if (this.$Analytics) {
        this.$Analytics.push({
          ...AnalyticCore.EVENT_BASE,
          event: 'cta_reply',
          position: get(this.current, 'name', ''),
          city: this.city?.city || '',
          vacancyCity: get(this.current, 'address.city', ''),
          manager: get(this.current, 'contacts.fullName', ''),
          employer: get(this.current, 'employer.name', ''),
          utm_campaign: get(this.$route.query, 'utm_campaign', ''),
          utm_content: get(this.$route.query, 'utm_content', ''),
          utm_medium: get(this.$route.query, 'utm_medium', ''),
          utm_source: get(this.$route.query, 'utm_source', ''),
          utm_term: get(this.$route.query, 'utm_term', ''),
          path: window.location.href,
          eventAction: AnalyticCore.EVENT_ACTION.click,
          eventCategory: AnalyticCore.EVENT_CATEGORY.interaction,
          eventLabel: 'button',
          eventContext: 'otclicknutsa',
          eventContent: get(this.current, 'id', ''),
          eventLocation: 'main',
        })
      }
    },
    sendAnalyticAllVacancy() {
      if (this.$Analytics) {
        this.$Analytics.push({
          ...AnalyticCore.EVENT_BASE,
          event: 'click_all_vacancy',
          city: this.city?.city || '',
          utm_campaign: get(this.$route.query, 'utm_campaign', ''),
          utm_content: get(this.$route.query, 'utm_content', ''),
          utm_medium: get(this.$route.query, 'utm_medium', ''),
          utm_source: get(this.$route.query, 'utm_source', ''),
          utm_term: get(this.$route.query, 'utm_term', ''),
          path: window.location.href,
          eventAction: AnalyticCore.EVENT_ACTION.click,
          eventCategory: AnalyticCore.EVENT_CATEGORY.interaction,
          eventLabel: 'link',
          eventContext: 'vacancyAll',
          eventContent: null,
          eventLocation: 'main',
        })
      }
      this.$router.push('/vacancies/?tag=HOT')
    },
    closeModal() {
      this.isModal = false
      this.showConfirm = false
    },
    openResponseModal() {
      this.showResponseModal = true
    },
    closeResponseModal() {
      this.showResponseModal = false
      this.closeModal()
    },
    openConfirm() {
      this.isModal = true
      this.showConfirm = true
    },
    closeConfirm() {
      this.showConfirm = false
      this.isModal = false
    },
  },
}
