//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import Jumbotron from '~/components/PageParts/Jumbotron'
import VacancyFilterCards from '~/components/PageParts/VacancyFilterCards'
import HotVacancies from '~/components/PageParts/HotVacancies'
import Benefits from '~/components/PageParts/Benefits'
import JumboSimple from '~/components/PageParts/JumboSimple'
import PartnersBanner from '~/components/PartnersBanner'
import EcoSystem from '~/components/EcoSystem'
import Reviews from '~/components/PageParts/Reviews'
import HomeForm from '~/components/HomeForm'
import YandexMaps from '~/components/Map/YandexMaps'
import 'swiper/css/swiper.css'
import { getCookie, setCookie } from '~/common/helpers/cookie'
import ModalBanner from '~/components/ModalBanner'
import MobileAppBanner from '~/components/MobileAppBanner'
import tildaContentMixin from '~/common/mixins/TildaContentMixin'
import { LOG_LEVEL, Logger } from '~/services/logger/log'

export default {
  name: 'PageHome',
  components: {
    YandexMaps,
    Jumbotron,
    VacancyFilterCards,
    HotVacancies,
    Benefits,
    JumboSimple,
    PartnersBanner,
    EcoSystem,
    Reviews,
    HomeForm,
    Swiper,
    SwiperSlide,
    ModalBanner,
    MobileAppBanner,
  },
  mixins: [tildaContentMixin],
  layout: 'main.layout',
  data() {
    return {
      banners: [],
      preview: false,
      russianBanner: false,
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 20,
        autoUpdate: false,
        loop: this.banners?.length > 1,
        direction: 'horizontal',
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 10000,
        },
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev',
        // },
      },
      isPopup: false,
      content: null,
    }
  },
  async fetch(context) {
    const { store, error } = context
    await store.dispatch('meta/getPageContent', context).catch(() => {
      error({ status: 404, message: 'Page not found' })
    })
  },
  head() {
    const head = {
      meta: [
        {
          hid: 'yandex-verification',
          name: 'yandex-verification',
          content: '042ed90d7ebc8acb',
        },
      ],
    }
    const data = this.currentPageMeta
    if (data && Object.keys?.(data)?.length) {
      head.title = data?.title || ''
      head.meta = head.meta.concat(data?.meta || [])
    }
    return head
  },
  computed: {
    ...mapState('core', ['config']),
    ...mapState('meta', ['pageContent', 'currentPageMeta']),
    ...mapState('vacancies', ['hotList']),
    isMobile() {
      if (process.client) {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
      }
      return undefined
    },
  },
  mounted() {
    if (!window?.rabotut?.refreshBanner) {
      window.rabotut = { ...window?.rabotut }
      window.rabotut.refreshBanner = this.checkBanner.bind(this)
    }
    this.checkBanner()
    if (this.$route.query?.mode === 'preview') {
      this.preview = true
      this.banners.push(this.$route.query)
    }
    if (!this.preview) {
      this.getBannersSlotId('sl_4ygT').then((res) => {
        const data = {
          ...res,
          sizes: '(max-width: 800px) 375px, (max-width: 1200px) 1100px, 100vw',
        }
        this.banners = [data]
      })
    }
    const availableBanner = getCookie('rabotut:user:mobileAppBanner')
    if (availableBanner !== '0') {
      setTimeout(() => this.showPopup(), 16000)
    }
  },
  errorCaptured(err, vm, info) {
    const component = vm?._name || vm?.$vnode?.tag || '---'
    const data = {
      level: LOG_LEVEL.ERROR,
      message: err?.message || info || '-',
      component,
    }
    const logger = Logger.get()
    logger.send(data)
  },
  methods: {
    ...mapActions('content', ['getListSystemPage']),
    ...mapActions('banners', ['getBannersSlotId']),
    checkBanner() {
      const resCase = getCookie('case')
      if (resCase && resCase === 'a') {
        this.russianBanner = true
        setTimeout(() => this.closeRussionBanner(), 7000)
      }
    },
    closeRussionBanner() {
      this.russianBanner = false
    },
    closePopup() {
      this.isPopup = false
      setCookie('rabotut:user:mobileAppBanner', '0')
    },
    showPopup() {
      this.isPopup = true
    },
  },
}
