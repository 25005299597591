import * as Yup from 'yup'
import { yupToFormErrors } from '~/common/utils/validation.js'
import yupLocaleRu from '~/locale/yupLocaleRu'

Yup.setLocale(yupLocaleRu)

export const HomeSchema = Yup.object().shape({
  fio: Yup.string().required('Заполните поле "Имя"'),
  city: Yup.string().required('Заполните поле "Город"'),
  phone: Yup.string()
    .required('Заполните поле "Номер телефона"')
    .length(16, 'Неверный формат ввода'),
  position: Yup.object()
    .required('Заполните поле "Должность"')
    .typeError('Заполните поле "Должность"'),
  agreeBox: Yup.boolean().required('Подтвердите согласие с условиями'),
})

export default {
  copyAttributes(attrs) {
    return {
      fio: attrs.fio || '',
      city: attrs.city || '',
      phone: attrs.phone || '',
      position: attrs.position || [],
      agreeBox: attrs.agreeBox || false,
    }
  },
  validate(form) {
    try {
      HomeSchema.validateSync(form, { abortEarly: false })
      return {}
    } catch (err) {
      return yupToFormErrors(err)
    }
  },
  async validateAt(field, form) {
    try {
      await HomeSchema.validateAt(field, form)
      return Promise.resolve({})
    } catch (err) {
      const errors = { [field]: err.errors[0] }
      return Promise.reject(errors)
    }
  },
}
