//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import { get, pickBy } from 'lodash'
import * as turf from '@turf/turf'
import AnalyticCore from '~/services/analytics'
import VacancyFilters from '~/components/VacancyFilters.vue'
import Modal from '~/components/UI/BaseModal.vue'
import ModalResponse from '~/components/ModalResponse.vue'
import ReplySuccessModal from '~/components/ReplySuccessModal.vue'
import DeviceType from '~/common/mixins/DeviceType'

const MOSCOW_COORDS = [37.61556, 55.75222]
const DEFAULT_ZOOM = 10

export default {
  name: 'VacancyYandexMap',
  components: {
    VacancyFilters,
    ModalResponse,
    Modal,
    ReplySuccessModal,
  },
  mixins: [DeviceType],
  data() {
    return {
      map: {},
      mapConfig: {
        zoom: DEFAULT_ZOOM,
        coords: MOSCOW_COORDS,
        showAllMarkers: true,
      },
      markerIcon: {
        layout: 'default#image',
        imageHref: require('~/assets/images/logo-small.png'),
        imageSize: [32, 32],
      },
      showMap: false,
      showForm: false,
      filters: {},
      selectedVacancyID: '',
      showConfirm: false,
      showReplyModal: false,
      current: {},
      loaded: {
        map: false,
        data: false,
      },
      objectManager: null,
      draw: {
        enabled: false,
        ctx: null,
        pixelCoords: [],
        offsets: [],
        lineConfig: {
          strokeColor: '#226cfc',
          lineWidth: 4,
        },
        polygonConfig: {
          strokeColor: '#0000ff',
          fillColor: '#8080ff',
          interactivityModel: 'default#transparent',
          strokeWidth: 4,
          opacity: 0.7,
        },
        shape: null,
      },
      drawButton: null,
    }
  },
  computed: {
    ...mapState('core', ['config']),
    ...mapState('user', {
      coords: 'coords',
    }),
    ...mapState('vacancies', {
      vacancies: 'mapData',
      loading: 'loading',
    }),
    ...mapState('core', {
      localCity: 'city',
    }),
    mapOptions() {
      return {
        zoomControl: false,
        dragging: true,
        tap: false,
      }
    },
    mapData() {
      const vacancies = this.vacancies
      if (!vacancies) return undefined
      const features = vacancies.features.map((item) => {
        return {
          ...item,
          properties: {
            ...item.properties,
            balloonContentBody: this.getBalloonBody(item),
            balloonContentHeader: `<span class="text-black">${item.properties.name}</span>`,
          },
        }
      })
      return {
        ...vacancies,
        features,
      }
    },
    isMobile() {
      if (process.client) {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
      }
      return undefined
    },
  },
  watch: {
    coords: {
      immediate: true,
      handler() {
        if (
          this.coords &&
          +this.coords.lat &&
          +this.coords.lng &&
          this.localCity?.city?.length
        ) {
          this.mapConfig.coords = [+this.coords.lng, +this.coords.lat]
        }
      },
    },
    mapData: {
      handler() {
        if (this.objectManager && this.mapData) {
          this.objectManager.removeAll()
          this.objectManager.add(this.mapData)
        }
      },
      deep: true,
    },
    loaded: {
      deep: true,
      handler() {
        if (this.loaded.map && this.loaded.data) {
          this.loadMarkersToMap()
        }
      },
    },
  },
  mounted() {
    this.showMap = true
    window.rabotut = {
      replyHandler: this.replyHandler.bind(this),
    }
  },
  methods: {
    ...mapActions('vacancies', {
      loadVacancies: 'loadMap',
    }),
    initHandler(obj) {
      this.map = obj
      this.loaded.map = true

      const customClusterIconLayout =
        window.ymaps.templateLayoutFactory.createClass(
          '<div class="balloon__icon">$[properties.geoObjects.length]</div>'
        )

      const customItemContentLayout =
        window.ymaps.templateLayoutFactory.createClass(
          // Флаг "raw" означает, что данные вставляют "как есть" без экранирования html.
          '<div class=ballon_body>{{ properties.balloonContentBody|raw }}</div>' +
            '<div class=ballon_footer>{{ properties.balloonContentFooter|raw }}</div>'
        )

      this.listButton = new window.ymaps.control.Button({
        data: {
          image: require('~/assets/icons/SVG/map-list-filter.svg'),
          // Текст всплывающей подсказки.
          title: 'Перейти к списку',
        },
        options: {
          selectOnClick: false,
        },
      })
      this.listButton.events.add(
        'click',
        function () {
          this.transferByList()
        }.bind(this)
      )
      this.map.controls.add(this.listButton, { float: 'right' })

      // eslint-disable-next-line
      this.objectManager = new window.ymaps.ObjectManager({
        clusterize: true,
        clusterDisableClickZoom: true,
        clusterOpenBalloonOnClick: true,
        clusterBalloonPanelMaxMapArea: 0,
      })
      // Одиночный балун
      this.objectManager.objects.options.set({
        iconLayout: 'default#image',
        iconImageHref: require('~/assets/images/logo-small.png'),
        iconImageSize: [32, 32],
      })
      // Множественный балун
      this.objectManager.clusters.options.set({
        clusterIconContentLayout: customClusterIconLayout,
        clusterBalloonItemContentLayout: customItemContentLayout,
      })
      this.map.geoObjects.add(this.objectManager)
    },
    moveTo(coords) {
      const { lat, lng } = coords
      this.map.flyTo([+lat, +lng] || MOSCOW_COORDS, DEFAULT_ZOOM)
    },
    onReply(item) {
      this.current = item
      if (this.current) {
        this.showForm = true
        this.openReplyModal()
        this.sendInteractionAnalytic()
      }
    },
    replyHandler(id) {
      if (id && this.vacancies?.features?.length) {
        const currentPoint = this.vacancies.features.find(
          (item) => item.id.toString() === id.toString()
        )
        if (currentPoint) {
          this.onReply(currentPoint.properties)
        }
      }
    },
    transferByList() {
      const path = this.$route.query
      const filters = {
        salaryFrom: path?.salaryFrom,
        salaryTo: path?.salaryTo,
        position: path?.position,
        employment: path?.employment,
        search: path?.search,
        metro: path?.metro,
        tag: path?.tag,
      }
      const targetFilters = pickBy(filters)
      let targetUrl = '/vacancies'
      if (Object.keys(targetFilters).length) {
        const targetSearchParams = new URLSearchParams(pickBy(targetFilters))
        targetUrl += `?${targetSearchParams}`
      }
      this.$router.push({ path: targetUrl })
    },
    closeModal() {
      this.showForm = false
      this.showConfirm = false
    },
    openReplyModal() {
      this.showReplyModal = true
    },
    closeReplyModal() {
      this.showReplyModal = false
      this.closeModal()
    },
    openConfirm() {
      this.showForm = true
      this.showConfirm = true
    },
    closeConfirm() {
      this.showConfirm = false
      this.showForm = false
    },
    sendInteractionAnalytic() {
      if (this.$Analytics) {
        this.$Analytics.push({
          ...AnalyticCore.EVENT_BASE,
          event: 'cta_reply',
          eventAction: AnalyticCore.EVENT_ACTION.click,
          eventCategory: AnalyticCore.EVENT_CATEGORY.interaction,
          position: get(this.current, 'name', ''),
          city: this.localCity?.city || '',
          vacancyCity: get(this.current, 'address.city', ''),
          manager: get(this.current, 'vacancy.contacts.fullName', ''),
          employer: get(this.current, 'employer.name', ''),
          utm_campaign: get(this.$route.query, 'utm_campaign', ''),
          utm_content: get(this.$route.query, 'utm_content', ''),
          utm_medium: get(this.$route.query, 'utm_medium', ''),
          utm_source: get(this.$route.query, 'utm_source', ''),
          utm_term: get(this.$route.query, 'utm_term', ''),
          path: window.location.href,
          eventLabel: 'button',
          eventContext: 'otclicknutsa',
          eventContent: get(this.current, 'id', ''),
          eventLocation: 'main',
        })
      }
    },
    sendAnalytic() {
      const close = document.querySelector(
        'ymaps[class$="-balloon__close-button"]'
      )
      if (close != null) {
        close.click()
      }
      if (this.$Analytics) {
        this.$Analytics.push({
          ...AnalyticCore.EVENT_BASE,
          event: 'click_map',
          city: this.localCity?.city || '',
          utm_campaign: get(this.$route.query, 'utm_campaign', ''),
          utm_content: get(this.$route.query, 'utm_content', ''),
          utm_medium: get(this.$route.query, 'utm_medium', ''),
          utm_source: get(this.$route.query, 'utm_source', ''),
          utm_term: get(this.$route.query, 'utm_term', ''),
          path: window.location.href,
          eventAction: AnalyticCore.EVENT_ACTION.click,
          eventCategory: AnalyticCore.EVENT_CATEGORY.interaction,
          eventLabel: null,
          eventContext: null,
          eventContent: 'mapSearch',
          eventLocation: 'main',
        })
      }
    },
    updateFilter(event) {
      this.selectedVacancyID = ''
      this.filters = { ...event }
      this.loaded.data = false
      const filterPayload = {}
      if (event?.position?.key) {
        filterPayload.position = event.position.key
      }
      if (event?.employment?.key) {
        filterPayload.employment = event.employment.key
      }
      if (event?.metro?.title) {
        filterPayload.metro = event.metro.title
      }
      if (event?.salaryFrom) {
        filterPayload.salaryFrom = +event.salaryFrom
      }
      if (event?.salaryTo) {
        filterPayload.salaryTo = +event.salaryTo
      }
      if (event?.tag) {
        filterPayload.tag = event.tag
      }
      if (this.objectManager) {
        // Удаляет все элементы коллекции.
        this.objectManager.removeAll()
      }
      this.loadVacancies(filterPayload).then(() => {
        this.loaded.data = true
      })
    },
    loadMarkersToMap() {
      if (this.objectManager && this.vacancies?.features?.length) {
        this.objectManager.add(this.mapData)
        this.map
          .setBounds(this.objectManager.getBounds(), { checkZoomRange: true })
          .then(() => {
            if (this.map.getZoom() > 10) this.map.setZoom(10)
          })
      }
    },
    getBalloonBody(item) {
      return (
        `<div class="map-popup flex flex-col" data-id="${item.properties.id}">` +
        `          <span class="salary">${
          item.properties.salary.start
            ? 'от ' + item.properties.salary.start + ' р'
            : 'Договорная'
        }</span>` +
        `<p class="py-1">` +
        `${item.properties?.short_description}` +
        `</p>` +
        `<a href="/vacancies/${item.properties.id}" class="salary text-sm md:font-base">Подробнее</a>` +
        `     <button` +
        `        class="w-36 p-2 button rounded-small mt-2 text-sm md:font-base font-bold balloon-button" ` +
        `        onclick="rabotut.replyHandler(${item.properties.id})"` +
        `      >` +
        `Откликнуться` +
        `      </button>` +
        `</div>`
      )
    },
    initDrawer() {
      // console.log('initDrawer')
      // console.log(this.mapData.features[0])
      // Получаем канвас
      const canvas = this.$refs.drawer
      this.draw.ctx = canvas.getContext('2d')
      this.draw.ctx.clearRect(0, 0, canvas.width, canvas.height)

      // удаляем старую фигуру если есть
      if (this.draw.shape) {
        this.map.geoObjects.remove(this.draw.shape)
      }

      // Задаем размеры канвасу как у карты.
      const rect = this.$refs.map.$el.getBoundingClientRect()
      canvas.style.width = rect.width + 'px'
      canvas.style.height = rect.height + 'px'
      canvas.width = rect.width
      canvas.height = rect.height

      // Показываем канвас. Он будет сверху карты из-за position: absolute.
      canvas.style.display = 'block'
      this.draw.ctx.fillStyle = '#f8f9fb80'
      this.draw.ctx.fillRect(0, 0, canvas.width, canvas.height)

      // Применяем стили.
      this.draw.ctx.strokeStyle = '#2f80ed'
      this.draw.ctx.lineWidth = this.draw.lineConfig.lineWidth
    },
    removeDraw() {
      // удаляем фигуру
      if (this.draw.shape) {
        this.map.geoObjects.remove(this.draw.shape)
        this.loaded.data = false
        this.loadVacancies().then(() => {
          this.loaded.data = true
        })
      }
    },
    onDrawStart(e) {
      this.draw.enabled = true
      this.draw.pixelCoords.push([e.pageX, e.pageY])
      this.draw.offsets.push([e.offsetX, e.offsetY])
    },
    onDraw(e) {
      if (this.draw.enabled) {
        const last = this.draw.offsets[this.draw.offsets.length - 1]
        this.draw.ctx.beginPath()
        this.draw.ctx.moveTo(last[0], last[1])
        this.draw.ctx.lineTo(e.offsetX, e.offsetY)
        this.draw.ctx.stroke()

        this.draw.pixelCoords.push([e.pageX, e.pageY])
        this.draw.offsets.push([e.offsetX, e.offsetY])
      }
    },
    onDrawFinish(e) {
      // console.log('onDrawFinish')
      if (this.draw.enabled) {
        // При отпускании мыши запоминаем координаты
        this.draw.pixelCoords.push([e.pageX, e.pageY])
        // console.log(this.draw)
        // Получаем пиксельные координаты отрисованного контура.
        const pixelCoords = this.draw.pixelCoords
        // Выключаем рисование
        this.draw.ctx.clearRect(
          0,
          0,
          this.$refs.drawer.width,
          this.$refs.drawer.height
        )
        this.$refs.drawer.style.display = 'none'
        this.draw = {
          ...this.draw,
          enabled: false,
          ctx: null,
          pixelCoords: [],
          offsets: [],
        }
        // console.log(pixelCoords)
        // Преобразуем пиксельные координаты в географические
        const projection = this.map.options.get('projection')
        const geoCoords = pixelCoords.map((item) =>
          projection.fromGlobalPixels(
            this.map.converter.pageToGlobal(item),
            this.map.getZoom()
          )
        )
        // console.log(geoCoords)
        // Получаем geoJSON ломаной из точек
        const lineString = turf.lineString(geoCoords)
        // console.log(lineString)
        // Преобразовываем ломаную в полигон (замыкаем последнюю точку с первой автоматически)
        const polygon = turf.lineToPolygon(lineString)
        // Упрощаем полигон (сокращаем количество точек)
        const simplifiedPolygon = turf.simplify(polygon, {
          tolerance: 0.01,
          highQuality: false,
        })
        // console.log(simplifiedPolygon)

        // Добавляем на карту многоугольник с полученными координатами.
        this.draw.shape = new window.ymaps.Polygon(
          turf.getCoords(simplifiedPolygon),
          {},
          {
            // Задаем опции геообъекта.
            // Цвет заливки.
            // strokeColor: '#226cfc',
            strokeColor: '#2f80ed',
            strokeWidth: 2,
            strokeOpacity: 1,
            fillColor: '#2f80ed',
            fillOpacity: 0.5,
            interactivityModel: 'default#transparent',
          }
        )
        this.map.geoObjects.add(this.draw.shape)

        // Убираем лишние точки
        this.objectManager.removeAll()

        // Выключаем кнопку
        this.drawButton.deselect()

        // Применяем фильтры
        this.applyInterceptFilter(simplifiedPolygon)
      }
    },
    applyInterceptFilter(shape) {
      this.loaded.data = false
      this.loadVacancies({ intercept: shape }).then(() => {
        this.loaded.data = true
      })
    },
  },
}
