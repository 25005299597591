//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { get } from 'lodash'
import AnalyticCore from '~/services/analytics'
import BaseButton from '~/components/UI/BaseButton.vue'
import Salary from '~/components/Salary.vue'
import LINE_METRO from '~/common/constants/lineMetro'

export default {
  name: 'BaseCardVacancy',
  components: {
    BaseButton,
    Salary,
  },
  props: {
    vacancyName: {
      type: String,
      default: '',
    },
    vacancyDesc: {
      type: String,
      default: '',
    },
    vacancySalary: {
      type: Number,
      default: 0,
    },
    link: {
      type: String,
      default: '/#',
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    hoverable: {
      type: Boolean,
      default: false,
    },
    rightButton: {
      type: Boolean,
      default: false,
    },
    metro: {
      type: Array,
      default: () => [],
    },
    vacancyAddress: {
      type: Object,
      default: () => ({}),
    },
  },
  typeCard: {
    type: String,
    default: 'card-btn',
    validator(value) {
      return ['card-btn'].includes(value) !== -1
    },
  },
  computed: {
    ...mapState('core', {
      localCity: 'city',
    }),
    classes() {
      return {
        'shadow-lg': this.shadow,
        scale: this.hoverable,
      }
    },
    description() {
      if (this.vacancyDesc.length > 84) {
        return this.vacancyDesc.substr(0, 84) + '...'
      } else {
        return this.vacancyDesc
      }
    },
    computedAddress() {
      return this.vacancyAddress?.value || ''
    },
    computedMetro() {
      return this.metro?.[0]?.name || ''
    },
  },
  methods: {
    sendAnalytic() {
      if (this.$Analytics) {
        this.$Analytics.push({
          ...AnalyticCore.EVENT_BASE,
          event: 'hot_vacancy',
          city: this.localCity?.city || '',
          utm_campaign: get(this.$route.query, 'utm_campaign', ''),
          utm_content: get(this.$route.query, 'utm_content', ''),
          utm_medium: get(this.$route.query, 'utm_medium', ''),
          utm_source: get(this.$route.query, 'utm_source', ''),
          utm_term: get(this.$route.query, 'utm_term', ''),
          path: window.location.href,
          eventAction: AnalyticCore.EVENT_ACTION.click,
          eventCategory: AnalyticCore.EVENT_CATEGORY.interaction,
          eventLabel: 'link',
          eventContext: 'vacancyHot',
          eventContent: this.link,
          eventLocation: 'main',
        })
      }
    },
    colorLineMetro(e) {
      if (e) {
        return `background: ${LINE_METRO[e]}`
      } else {
        return 'background: white'
      }
    },
  },
}
