//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import VModelMixin from '~/common/mixins/VModelMixin'

export default {
  name: 'TagsRadioButtons',
  mixins: [VModelMixin],
  data() {
    return {
      selected: '',
    }
  },
  computed: {
    ...mapState('dictionary', ['tags']),
    radioClasses() {
      const baseClass = 'radio'
      const res = [baseClass]
      if (this.model) {
        res.push('checked')
      }
      return res
    },
  },
  methods: {
    onChange(event) {
      const data = event?.target?.value
      if (data === this.value) {
        this.change(false)
      } else {
        this.change(event)
      }
      this.$emit('onChange')
    },
  },
}
