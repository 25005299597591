//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseCard from '~/components/UI/Cards/BaseCard.vue'

export default {
  name: 'CommonBenefits',
  components: {
    BaseCard,
  },
  data() {
    return {
      benefitsData: [
        { icon: 'job_seeker', title: '1000+ актуальных вакансий' },
        { icon: 'cooperation', title: '500 000+ нашли работу у нас' },
        { icon: 'interview', title: 'Отклик без резюме' },
        { icon: 'home', title: 'Работа рядом с домом' },

        { icon: 'salary', title: 'Регулярные выплаты' },
        { icon: 'profile', title: 'Проверенные работодатели' },
        { icon: 'working', title: 'Дистанционное оформление за 24 часа' },
        { icon: 'schedule', title: 'Гибкий график' },
      ],
    }
  },
}
