export const LINE_METRO_TYPE = {
  SOKOLNICHESKAYA: 'Сокольническая', // Сокольническая
  ZAMOSKVORETSKAYA: 'Замоскворецкая', // Замоскворецкая
  ARBATSKO_POKROVSKAYA: 'Арбатско-Покровская', // Арбатско-Покровская
  FILOVSKAYA: 'Филёвская', // Филёвская
  KOLTSEVAYA: 'Кольцевая', // Кольцевая
  KALUZHSKO_RIZHSKAYA: 'Калужско-Рижская', // Калужско-Рижская
  TAGANSKO_KRASNOPRESNENSKAYA: 'Таганско-Краснопресненская', // Таганско-Краснопресненская
  KALININSKO_SOLNTSEVSKAYA: 'Калининско-Солнцевская', // Калининско-Солнцевская
  KALININSKAYA: 'Калининская', // Калининская
  SOLNTSEVSKAYA: 'Солнцевская', // Солнцевская
  SERPUKHOVSKO_TIMIRYAZEVSKAYA: 'Серпуховско-Тимирязевская', // Серпуховско-Тимирязевская
  LYUBLINSKO_DMITROVSKAYA: 'Люблинско-Дмитровская', // Люблинско-Дмитровская
  BOLSHAYA_KOLTSEVAYA_LINIYA: 'Большая кольцевая линия', // Большая Кольцевая Линия
  BUTOVSKAYA: 'Бутовская', // Бутовская
  MONORELS: 'Монорельс', // Московский монорельс
  CENTER_RING: 'МЦК', // Центральное Кольцо
  NEKRASOVSKAYA: 'Некрасовская', // Некрасовская
  BELARUSIAN_SAVELOVSKY: 'Белорусско-Савеловский', // Белорусско-Савеловский МЦД-1
  MCD_1: 'МЦД1', // МЦД-1
  KURSKO_RIZHSKIY: 'Курско-Рижский', // МЦД-2
  MCD_2: 'МЦД2', // МЦД-2
}

export const LINE_METRO = {
  [LINE_METRO_TYPE.SOKOLNICHESKAYA]: '#cd0506',
  [LINE_METRO_TYPE.ZAMOSKVORETSKAYA]: '#0a6f20',
  [LINE_METRO_TYPE.ARBATSKO_POKROVSKAYA]: '#072889',
  [LINE_METRO_TYPE.FILOVSKAYA]: '#069cd3',
  [LINE_METRO_TYPE.KOLTSEVAYA]: '#7f0000',
  [LINE_METRO_TYPE.KALUZHSKO_RIZHSKAYA]: '#ff7f00',
  [LINE_METRO_TYPE.TAGANSKO_KRASNOPRESNENSKAYA]: '#92007b',
  [LINE_METRO_TYPE.KALININSKO_SOLNTSEVSKAYA]: '#FFD702',
  [LINE_METRO_TYPE.KALININSKAYA]: '#FFD702',
  [LINE_METRO_TYPE.SOLNTSEVSKAYA]: '#FFD702',
  [LINE_METRO_TYPE.SERPUKHOVSKO_TIMIRYAZEVSKAYA]: '#a2a5b4',
  [LINE_METRO_TYPE.LYUBLINSKO_DMITROVSKAYA]: '#8cce3a',
  [LINE_METRO_TYPE.BOLSHAYA_KOLTSEVAYA_LINIYA]: '#29b1a6',
  [LINE_METRO_TYPE.BUTOVSKAYA]: '#b2dae7',
  [LINE_METRO_TYPE.MONORELS]: '#9999FF',
  [LINE_METRO_TYPE.CENTER_RING]: '#F76093',
  [LINE_METRO_TYPE.NEKRASOVSKAYA]: '#7D7D7D',
  [LINE_METRO_TYPE.BELARUSIAN_SAVELOVSKY]: '#EFA81A',
  [LINE_METRO_TYPE.MCD_1]: '#EFA81A',
  [LINE_METRO_TYPE.MCD_2]: '#DE4285',
  [LINE_METRO_TYPE.KURSKO_RIZHSKIY]: '#DE4285',
}

export default LINE_METRO
