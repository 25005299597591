//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import '~/assets/icons/SVG/cross.svg'

export default {
  name: 'ModalBanner',
  components: {},
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    onEscPressed(e) {
      // window.isEscapeLocked - lock escape on work with input fields
      if (e.key === 'Escape' && !window.isEscapeLocked) {
        this.onClose()
      }
    },
  },
}
