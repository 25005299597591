//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseButton from '~/components/UI/BaseButton.vue'

export default {
  name: 'ReplySuccessModal',
  components: {
    BaseButton,
  },
  data() {
    return {}
  },
  methods: {
    close() {
      this.$emit('close', false)
    },
  },
}
