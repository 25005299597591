//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import { get } from 'lodash'
import AnalyticCore from '~/services/analytics'
import utmMixin from '~/common/mixins/UtmMixin.js'
import BaseInput from '~/components/UI/form/BaseInput.vue'
import BaseButton from '~/components/UI/BaseButton.vue'
import BaseSelect from '~/components/UI/form/BaseSelect.vue'
import ReplySuccessModal from '~/components/ReplySuccessModal.vue'
import Modal from '~/components/UI/BaseModal.vue'
import CityPicker from '~/components/City/CityPicker.vue'
import HomeForm from '~/forms/HomeForm'
import BaseCheckbox from '~/components/UI/form/BaseCheckbox.vue'
import { getCookie } from '~/common/helpers/cookie'

export default {
  name: 'HomeForm',
  components: {
    BaseInput,
    BaseButton,
    BaseSelect,
    Modal,
    ReplySuccessModal,
    CityPicker,
    BaseCheckbox,
  },
  mixins: [utmMixin],
  data() {
    return {
      form: {
        fio: '',
        city: '',
        phone: '',
        position: '',
        agreeBox: false,
      },
      errors: {
        phone: '',
        fio: '',
        position: '',
        city: '',
        agreeBox: '',
      },
      isModal: false,
      showPicker: false,
      showConfirm: false,
      showResponseModal: false,
    }
  },
  computed: {
    ...mapState('core', {
      localCity: 'city',
    }),
    ...mapState('dictionary', ['positions']),
  },
  mounted() {
    this.form.city = this?.localCity?.city || ''
  },
  methods: {
    ...mapActions('forms', ['createClaim']),
    onInputField(field) {
      this.$set(this.errors, field, '')
    },
    onPaste(ev, field) {
      let phone = ev.clipboardData.getData('text')
      phone = phone.replace(/[()\s-+]/g, '')
      if (Number(phone.charAt(0)) === 7 || Number(phone.charAt(0)) === 8) {
        phone = phone.slice(1)
      }
      this.$set(this.form, field, phone)
      this.onInputField(field)
    },
    onBlurField(field) {
      HomeForm.validateAt(field, this.form)
        .then(() => {
          this.$set(this.errors, field, '')
        })
        .catch((err) => {
          this.$set(this.errors, field, err[field])
        })
    },
    showModal() {
      this.isModal = true
    },
    closeModal() {
      this.isModal = false
      this.showConfirm = false
      this.showForm = false
    },
    openConfirm() {
      this.showConfirm = true
    },
    closeConfirm() {
      this.showConfirm = false
      this.isModal = false
    },
    submit() {
      this.errors = HomeForm.validate(this.form)

      if (!Object.keys(this.errors).length) {
        const phone = get(this.form, 'phone', '')
        this.sendAnalytic()

        const payload = {
          sendTo: '',
          category: 'SEARCH_WORK',
          contacts: {
            fullName: this.form.fio,
            phone: phone.replace(/[()\s-+]/g, ''),
            email: '',
          },
          position: this.form.position.name,
          userComment: '',
          userWish: '',
          utm: JSON.parse(getCookie('rabotut:utm')),
          userContent: '',
        }
        this.createClaim(payload)
          .then(() => {
            this.showModal()
            this.openConfirm()
          })
          .catch((error) => {
            console.error(error?.message || 'Ошибка отправки')
          })
      }
    },
    sendAnalytic() {
      if (this.$Analytics) {
        this.$Analytics.push({
          ...AnalyticCore.EVENT_BASE,
          event: 'home_form',
          position: this.form.position,
          city: this?.localCity?.city || '',
          vacancyCity: this.form.city,
          path: window.location.href,
          ...this.utm,
          eventAction: AnalyticCore.EVENT_ACTION.click,
          eventCategory: AnalyticCore.EVENT_CATEGORY.conversion,
          eventLabel: 'form',
          eventContext: 'contactsForm',
          eventContent: this.form,
          eventLocation: 'main',
        })
      }
    },
    onChangeCity() {
      this.showPicker = true
    },
    closePicker() {
      this.showPicker = false
    },
    onConfirm(event) {
      this.confirmedCity = event.city
      this.showPicker = false
      this.form.city = event.city
    },
  },
}
