import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4e805c09&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=4e805c09&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e805c09",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PartnersBanner: require('/builds/rabotut/ui-web/project/components/PartnersBanner.vue').default,EcoSystem: require('/builds/rabotut/ui-web/project/components/EcoSystem.vue').default,HomeForm: require('/builds/rabotut/ui-web/project/components/HomeForm.vue').default,MobileAppBanner: require('/builds/rabotut/ui-web/project/components/MobileAppBanner.vue').default,ModalBanner: require('/builds/rabotut/ui-web/project/components/ModalBanner.vue').default})
