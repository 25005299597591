//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseCard',
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    hoverable: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: String,
      default: 'p-3',
    },
    inlineResponsive: {
      type: Boolean,
    },
  },
  computed: {
    classes() {
      return {
        'shadow-xl': this.shadow,
        [`${this.padding}`]: this.padding,
        scale: this.hoverable,
        'cursor-pointer': this.hoverable,
      }
    },
  },
  methods: {
    onClick(callback) {
      this.$emit('click', this.link)
      if (this.link) {
        callback()
      }
    },
  },
}
