//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'EcoSystem',
  data() {
    return {
      cardsData: [
        {
          title: '',
          class: '',
          classEco: '',
          radius: '',
          classText: '',
          text: '',
        },
        {
          title: 'Обучение',
          classEco: 'transform -translate-y-1/2 left-12',
          text: 'Мы обучаем людей новой профессии, рассказываем об обязанностях и как правильно их выполнять',
        },
        {
          title: 'Развитие',
          classEco: 'transform -translate-y-1/2 left-10',
          text: 'Помогаем раскрываться профессионально, раскрываем сильные стороны работников',
        },
        {
          title: 'Доступ к учебным материалам',
          classEco: 'transform -translate-y-1/2 left-12',
          text: 'Даём доступ к обучающим материалам на время адаптации на новой работе',
        },
        {
          title: 'Акции, бонусы и конкурсы',
          classEco: 'transform -translate-x-1/2 top-12',
          text: 'Проводим интерактивные мероприятия, даём бонусы партнерам ',
        },
        {
          title: 'Помощь в подборе вакансий',
          classEco: 'transform -translate-y-1/2 right-12',
          text: 'Помогаем выбирать вакансии, опираясь на ваш опыт и навыки',
        },
        {
          title: 'Поддержка сотрудников и соискателей',
          classEco: 'transform -translate-y-1/2 right-10',
          text: 'Мы обучаем людей новой профессии',
        },
        {
          title: 'Уникальная система адаптации',
          classEco: 'transform -translate-y-1/2 right-12',
          text: 'Следим за состоянием работника и оказываем психологическую поддержку',
        },
      ],
    }
  },
  mounted() {
    const radius = 188
    const fields = document.querySelectorAll('.eco-card__r-wrap')
    const container = document.querySelector('.eco__container')
    const width = container.offsetWidth
    const height = container.offsetHeight
    let angle = -1.55
    const step = (2 * Math.PI) / fields.length

    for (let i = 0; i < fields.length; i++) {
      fields[i].style.position = 'absolute'
      const x = Math.round(
        width / 2 + radius * Math.cos(angle) - fields[i].offsetWidth / 2
      )
      const y = Math.round(
        height / 2 + radius * Math.sin(angle) - fields[i].offsetHeight / 2
      )
      fields[i].style.left = x + 'px'
      fields[i].style.top = y + 'px'

      angle += step
    }
  },
}
