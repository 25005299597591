//
//
//
//

export default {
  name: 'MobileAppBanner',
  methods: {
    onClick() {
      if (process.client) {
        const url =
          'https://redirect.appmetrica.yandex.com/serve/964650471228747748'
        window.open(url, '_blank').focus()
      }
    },
  },
}
