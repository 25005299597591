//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { get } from 'lodash'
import AnalyticCore from '~/services/analytics'
import BaseButton from '~/components/UI/BaseButton.vue'

export default {
  name: 'PartnersBanner',
  components: {
    BaseButton,
  },
  props: {
    banner: {
      type: Object,
      default: () => {},
    },
    preview: {
      type: Boolean,
      default: false,
    },
    russianBanner: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('core', {
      localCity: 'city',
    }),
    imgLeft() {
      return this.banner.type === 'imgLeft'
    },
    imgClass() {
      return {
        'rounded-l-middle lg:rounded-l-large': !this.imgLeft,
        'rounded-r-middle lg:rounded-r-large': this.imgLeft,
      }
    },
    imgCubeClass() {
      return {
        '-rotate-12 right-20': !this.imgLeft,
        'rotate-12 left-20': this.imgLeft,
      }
    },
    alignClass() {
      return {
        'md:justify-end sm:pl-0 sm:pr-10 lg:pr-20': this.imgLeft,
        'md:justify-between sm:pr-0 sm:pl-10 lg:pl-20 ': !this.imgLeft,
      }
    },
  },
  methods: {
    sendAnalytic() {
      if (this.$Analytics) {
        this.$Analytics.push({
          ...AnalyticCore.EVENT_BASE,
          event: 'home_banner',
          city: this.localCity?.city || '',
          utm_campaign: get(this.$route.query, 'utm_campaign', ''),
          utm_content: get(this.$route.query, 'utm_content', ''),
          utm_medium: get(this.$route.query, 'utm_medium', ''),
          utm_source: get(this.$route.query, 'utm_source', ''),
          utm_term: get(this.$route.query, 'utm_term', ''),
          path: window.location.href,
          eventAction: AnalyticCore.EVENT_ACTION.click,
          eventCategory: AnalyticCore.EVENT_CATEGORY.interaction,
          eventLabel: 'link',
          eventContext: 'stock',
          eventContent: this.banner.id,
          eventLocation: 'main',
        })
      }
      this.$router.push(this.banner.href)
    },
    sendAnalyticMyGig() {
      if (this.$Analytics) {
        this.$Analytics.push({
          ...AnalyticCore.EVENT_BASE,
          event: 'home_banner',
          city: this.localCity?.city || '',
          utm_campaign: get(this.$route.query, 'utm_campaign', ''),
          utm_content: get(this.$route.query, 'utm_content', ''),
          utm_medium: get(this.$route.query, 'utm_medium', ''),
          utm_source: get(this.$route.query, 'utm_source', ''),
          utm_term: get(this.$route.query, 'utm_term', ''),
          path: window.location.href,
          eventAction: AnalyticCore.EVENT_ACTION.click,
          eventCategory: AnalyticCore.EVENT_CATEGORY.interaction,
          eventLabel: 'link',
          eventContext: 'stock',
          eventContent: this.banner.id,
          eventLocation: 'main',
        })
      }
      window.open(
        'https://redirect.appmetrica.yandex.com/serve/459912651426804314',
        '_blank'
      )
    },
    openPageImageBanner(href) {
      window.open(`${href}`)
    },
  },
}
