//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min.js'
import 'vue-slider-component/dist-css/vue-slider-component.css'
import { debounce } from 'lodash'
import BaseInput from '~/components/UI/form/BaseInput.vue'

export default {
  name: 'SalarySlider',
  components: { VueSlider, BaseInput },
  props: {
    from: {
      type: Number,
      default: 0,
    },
    to: {
      type: Number,
      default: 100000,
    },
    min: {
      type: Number,
      default: 5000,
    },
    max: {
      type: Number,
      default: 100000,
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      slider: {
        from: 0,
        to: 100000,
        minThumb: 0,
        maxThumb: 0,
      },
      data: [0, 100000],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.parseUrl()
      },
    },
  },
  mounted() {
    this.parseUrl()
  },
  methods: {
    parseUrl() {
      const url = this.$route.query
      this.slider.from = parseInt?.(url?.salaryFrom || '0', 10)
      this.slider.to = parseInt?.(url?.salaryTo || '100000', 10)
      this.data = [+url?.salaryFrom || 0, +url?.salaryTo || 100000]
    },
    onChangeSalary(e) {
      const [minVal, maxVal] = e
      const payload = {
        from: parseInt?.(minVal, 10) || 0,
        to: parseInt?.(maxVal, 10) || 0,
      }
      this.slider.from = parseInt?.(minVal, 10) || 0
      this.slider.to = parseInt?.(maxVal, 10) || 0
      this.onChangeSlider(payload)
    },

    onChangeSlider: debounce(function (e) {
      this.$emit('onChange', e)
    }, 300),

    handleChange: debounce(function (e, field) {
      const value = e ? parseInt?.(e, 10) : 0
      const newVal = {
        from: parseInt?.(this.slider.from, 10) || 0,
        to: parseInt?.(this.slider.to, 10) || 0,
      }
      if (field === 'from') {
        newVal.from =
          value > this.max || value > (this.slider.to || 0)
            ? this.max - 3000
            : value
      }
      if (field === 'to') {
        newVal.to = value < this.min ? this.min : value
      }
      this.data = [newVal.from, newVal.to]
      this.$emit('onChange', newVal)
    }, 800),
  },
}
